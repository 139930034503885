import React from 'react'
import Hoc from './Hoc'
import Slider from 'react-slick';

function OilGas() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    swipe: true, // touch swipe
    arrows: false, //nex and previous button off
    pauseOnHover: false, // Set to false to continue autoplay on hover
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
    <div className='bg_oil d-flex justify-content-center align-items-center '>

<div className=' tex' data-aos="zoom-in"
  data-aos-delay="50"
  data-aos-duration="1500">
    <h2 className='text-white'>
    Oil & Gas 
    </h2>
</div>

</div>



<div className='container my-5'>

<div className='row'>
<h3 className='borderbotoom fw-bold mx-auto'>
 RMV International - Leading Manufacturer in the Oil & Gas Industry

 </h3>
<div className='col-lg-6'>

    <div className='content_precision my-3'>


 <p>
 Welcome to RMV International, your premier destination for high-quality solutions in the oil and gas sector. With <span className='fw-bold'>10 years</span> of industry experience, we specialize in designing, manufacturing, and supplying a wide range of cutting-edge products and services tailored to meet the unique needs of our clients worldwide.
 </p>
 <p>
 At RMV International, we take pride in our commitment to excellence and innovation. Our team of dedicated professionals combines expertise with passion to deliver industry-leading solutions that drive success for our customers. Learn more about our company's mission, values, and history.

 </p>
 
 <h4 className='fw-bold borderbotoom'>Our Products and Services:
</h4>
<p>
Explore our comprehensive range of products and services designed to support every stage of oil and gas operations, including:

</p>

<ul>
    <li>
    Valves and Actuators
    </li>
    <li> Pumping and Compression Solutions
</li>
<li>
Drilling Equipment and Tools

</li>
<li> Instrumentation and Control Systems
</li>
<li>Subsea Technologies
</li>
<li>Structural Components and Fabrication Services
</li>
</ul>
<p>Discover how our cutting-edge solutions can optimize efficiency, enhance reliability, and ensure safety across your operations.

</p>

    </div>

</div>

<div className='col-lg-6'>

<div className='mt-5'>
<img src={require('../assets/images/oil gas content.jpg')} className='img-fluid object-fit-cover' style={{height:'500px'}} alt="" />
</div>

</div>


</div>

<div className='content_precision'>
<p> Unparalleled Quality: Our products are manufactured to the highest standards of quality and precision, ensuring reliability and performance in even the most challenging environments.
</p>

<p>
Industry Expertise: With decades of experience in the oil and gas industry, we possess the knowledge and insights necessary to deliver tailored solutions that meet our clients' unique requirements.

</p>
<p>
Customer-Centric Approach: At RMV International, we prioritize customer satisfaction above all else. Our dedicated team is committed to providing exceptional service and support at every step of the process.

</p>
<p>
Global Reach: With a global network of partners and distributors, we have the capability to serve clients around the world, delivering timely solutions wherever they are needed.

</p>
</div>


<div className='content_precision'>

<p>In the oil and gas industry, Computer Numerical Control (CNC) parts play a crucial role in various applications, ensuring precision, reliability, and efficiency. Here are some common uses of CNC parts in the oil and gas sector:
</p>

<p><strong>1. Valves and Actuators:</strong>CNC machining is employed to manufacture valve bodies, stems, seats, and other components critical for controlling the flow of fluids in pipelines and processing equipment. Actuators, used to automate valve operation, often contain CNC-machined parts for precise control.
</p>
<p className='mt-4'><strong>2. Pumps and Compressors:</strong>CNC machining is utilized to fabricate components for pumps and compressors, including impellers, housings, shafts, and seals. These components must meet tight tolerances to ensure optimal performance and durability in demanding operating conditions.

</p>
<p className='mt-4'><strong>3. Drilling Equipment:</strong>CNC-machined parts are integral to drilling rigs, including components for drill bits, drill collars, mud motors, and other downhole tools. These parts are designed to withstand high-pressure and high-temperature environments encountered during drilling operations.

</p>
<p className='mt-4'><strong>4. Instrumentation and Control Systems:</strong> CNC machining is used to produce precision components for instrumentation and control systems, such as sensor housings, connectors, and mounting brackets. These components are essential for monitoring and regulating various parameters in oil and gas facilities.


</p>
<p className='mt-4'><strong>5. Manifolds and Flow Control Systems:</strong>  CNC-machined manifolds and flow control systems are critical for directing the flow of fluids within oil and gas production facilities. These components are designed to optimize flow rates, minimize pressure drops, and ensure reliable operation.
</p>
<p className='mt-4'><strong>6. Subsea Equipment:</strong> In offshore oil and gas production, CNC parts are used in subsea equipment such as blowout preventers (BOPs), Christmas trees, and subsea wellheads. These components must withstand harsh underwater conditions and maintain tight tolerances for sealing and performance.

</p>
<p className='mt-4'><strong>7. Structural Components:</strong>CNC machining is also employed to fabricate structural components used in oil and gas infrastructure, including frames, supports, brackets, and flanges. These components provide structural integrity and support for various equipment and piping systems.


</p>
<p>Overall, CNC machining plays a vital role in the oil and gas industry by enabling the production of precision components essential for exploration, production, processing, and transportation operations. These parts help improve efficiency, reliability, and safety across the entire oil and gas value India.
</p>
</div>


</div>


<div className='mb-5'>
<Slider {...settings}>



<div className='p-3'>

<img src={require('../assets/industry/OIl & Gas Slider/1520061777677.jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/OIl & Gas Slider/1520124317226.jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/OIl & Gas Slider/1520151857952.jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/OIl & Gas Slider/1520232070842.jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/OIl & Gas Slider/Precision-CNC-Machined-Oil-Gas-Components.jpg')} className='img-fluid w-100 object-fit-cover' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/OIl & Gas Slider/steel-forgings-500x500.webp')} className='img-fluid w-100 object-fit-cover' style={{height:'250px'}} alt="" />

</div>



</Slider>
</div>
    
    </>
  )
}

export default Hoc(OilGas)