import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import Hoc from './Hoc';
import Swal from 'sweetalert2';

function Contact() {

  const [obj, setobj] = useState({});
  const [errormsg, seterrormsg] = useState({});
  const [blankobj, setblankobj] = useState({ });

  // const [formData, setFormData] = useState({
  //   firstName: obj.firstname,
  //   lastName: obj.lastName,
  //   email: obj.email,
  //   company: obj.company,
  //   country: obj.country,
  //   subject: obj.general,
  //   message: obj.message
  // });

 

  const handleChange = (e) => {
    obj[e.target.name] = e.target.value;
    blankobj[e.target.name] = "";


    
    if (e.target.name === "firstname") {
      if (e.target.value?.length <= 0) {
        errormsg.firstname = "Firstname is requird";
      } else if (!/^[a-zA-Z]+$/.test(e.target.value)) {
        errormsg.firstname = "Firstname must be in alphabets characters";
      } else {
        delete errormsg.firstname;
      }
    }
    if (e.target.name === "lastName") {
      if (e.target.value?.length <= 0) {
        errormsg.lastName = "Lastname is requird";
      } else if (!/^[a-zA-Z]+$/.test(e.target.value)) {
        errormsg.lastName = "lastname must be in alphabets characters";
      } else {
        delete errormsg.lastName;
      }
    }
    // email validation
    if (e.target.name === "email") {
      if (obj.email?.length <= 0) {
        errormsg.email = "email is required";
      } else if (
        !(
          obj.email?.includes("@") 
        )
      ) {
        errormsg.email = "email is not valid";
      } else {
        errormsg.email = "";
      }
    }

    if(e.target.name==='company'){
      if (e.target.value?.length <= 0) {
        errormsg.company = "company is requird";
      } else {
         errormsg.company='';
      }
    }
    if(e.target.name==='country'){
      if (e.target.value?.length <= 0) {
        errormsg.country = "country is requird";
      } else {
         errormsg.country='';
      }
    }
    if(e.target.name==='general'){
      if (e.target.value?.length <= 0) {
        errormsg.general = "general is requird";
      } else {
         errormsg.general='';
      }
    }
    if(e.target.name==='message'){
      if (e.target.value?.length <= 0) {
        errormsg.message = "message is requird";
      } else {
         errormsg.message='';
      }
    }

    
    seterrormsg({ ...errormsg });
    setobj({ ...obj });
    setblankobj({ ...blankobj });


  };

  const savedata = (e) => {
    setobj({...obj})
    // e.preventDefault();
     // validation form 
     if (obj.firstname === "" || obj.firstname === undefined) {
      errormsg.firstname = "please enter your firstname";
    }
     if (obj.lastName === "" || obj.lastName === undefined) {
      errormsg.lastName = "please enter your lastName";
    }
     if (obj.company === "" || obj.company === undefined) {
      errormsg.company = "please enter your company";
    }
    if (obj.email === "" || obj.email === undefined) {
      errormsg.email = "please enter your email";
    }

    if (obj.country === "" || obj.country === undefined) {
      errormsg.country = "please enter your country";
    }
    if (obj.general === "" || obj.general === undefined) {
      errormsg.general = "please enter your general";
    }
    if (obj.message === "" || obj.message === undefined) {
      errormsg.message = "please enter your message";
    }

    if (Object.values(errormsg).every((x) => x === "")) {
      Swal.fire({
        title: "Thank you",
        text: "We will get in touch with you shortly",
        icon: "success"
      });
      emailjs.send('service_mtmnyli', 'template_h1o6laq', obj,{publicKey: 'Rr6-hep_NaSpwlnB2'})
      .then((response) => {
        console.log('Email sent successfully:', response);
        // Reset form fields or show a success message to the user
      }, (error) => {
        console.error('Email sending failed:', error);
        // Handle error, maybe show a message to the user
      });
     
      setobj({ ...obj });
          setobj({ ...blankobj });

          seterrormsg({ ...errormsg });

    }




  };
  return (
    <>
     <div className="container-fluid ">
      <div className="container-fluid contact_header ">
        <p className="contact_text">Contact Us</p>
      </div>
    </div>



    <div className="container-fluid ">
      <div className="row container-fluid  this_row mb-5">
        <div className="col-lg-6 col-md-6 col-sm-12 first_col">
          <br />
          <p>
            <strong>Fields marked with an * are required</strong>
          </p>
          <p>
            This form collects personal data for purposes of newsletters and
            sales inquiries. View our Privacy Policy for more details.
          </p>
         {/* <form action="">
         <Form.Label htmlFor="inputPassword5">
            <strong>First Name</strong>
          </Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            required
            aria-describedby="passwordHelpBlock"
          />
          <br />
          <Form.Label htmlFor="inputPassword5">
            <strong>Last Name</strong>
          </Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
          />
          <br />
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <strong>Email address *</strong>
            </Form.Label>
            <Form.Control type="email" placeholder="name@example.com" />
          </Form.Group>
          <br />
          <Form.Label htmlFor="inputPassword5">
            <strong>Company *</strong>
          </Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            required
          />
          <br />
          <Form.Label htmlFor="inputPassword5">
            <strong>Country *</strong>
          </Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            required
          />
          <br />
          <Form.Label>
            <strong>Subject *</strong>
          </Form.Label>
          <Form.Select aria-label="Default select example">
            <option>General Inquirey</option>
            <option value="1">Request Pricing and Availability</option>
            <option value="2">Coustomer Service</option>
          </Form.Select>
          <br />
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <strong>Message *</strong>
            </Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
          <Button variant="outline-dark">Submit</Button> <br />
          <br />
         </form> */}
         <form >
      <div className="mb-3" >
        <label htmlFor="inputFirstName" className="form-label"><strong>First Name</strong></label>
        <input type="text" className="form-control"
         id="firstName" name='firstname' value={obj.firstname} onChange={handleChange} required  />
          <span className="text-danger">{errormsg.firstname}</span>
      </div>
      <br />
      <div className="mb-3">
        <label htmlFor="inputLastName" className="form-label"><strong>Last Name</strong></label>
        <input type="text" name='lastName' className="form-control" id="lastName" value={obj.lastName} onChange={handleChange} aria-describedby="lastNameHelpBlock" />
        <span className="text-danger">{errormsg.lastName}</span>
      </div>
      <br />
      <div className="mb-3">
        <label htmlFor="inputEmail" className="form-label"><strong>Email address *</strong></label>
        <input type="email" name='email' className="form-control" id="email" value={obj.email} onChange={handleChange} placeholder="name@example.com" />
        <span className="text-danger">{errormsg.email}</span>
      </div>
      <br />
      <div className="mb-3">
        <label htmlFor="inputCompany" className="form-label"><strong>Company *</strong></label>
        <input type="text" name='company' className="form-control" id="company" value={obj.company} onChange={handleChange} aria-describedby="companyHelpBlock" required />
        <span className="text-danger">{errormsg.company}</span>
      </div>
      <br />
      <div className="mb-3">
        <label htmlFor="inputCountry" className="form-label"><strong>Country *</strong></label>
        <input type="text" name='country' className="form-control" id="country" value={obj.country} onChange={handleChange} aria-describedby="countryHelpBlock" required />
        <span className="text-danger">{errormsg.country}</span>
      </div>
      <br />
      <div className="mb-3">
        <label htmlFor="inputSubject" className="form-label"><strong>Subject *</strong></label>
        <select className="form-select" name='general' id="subject" value={obj.general} onChange={handleChange} aria-label="Default select example">
          <option value="">General Inquiry</option>
          <option value="Request Pricing and Availability">Request Pricing and Availability</option>
          <option value="Customer Service">Customer Service</option>
        </select>
        <span className="text-danger">{errormsg.general}</span>
      </div>
      <br />
      <div className="mb-3">
        <label htmlFor="inputMessage" className="form-label"><strong>Message *</strong></label>
        <textarea className="form-control" name='message' id="message" value={obj.message} onChange={handleChange} rows="3"></textarea>
        <span className="text-danger">{errormsg.message}</span>
      </div>
      <button type="submit"  className="btn btn-outline-dark" onClick={savedata}>Submit</button>
      <br /><br />
    </form>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 second-col h-25 p-4 ">
          <p>
            We’re always looking for feedback from our valued customers to learn
            how we can improve.
          </p>
          <p>
            If you have questions or need help please feel free to contact us.
          </p>
          <p>
          📍<span className='fw-bold'>Office:</span> &nbsp; 
          212-213-214, Yogi Arcade, Opp Pramukhchhaya Soc, Yogi Chowk, Surat-395010

          </p><br/>
          <p>
          📍<span className='fw-bold'>Unit:</span>&nbsp; 22-23,  Shiv Shakti campus ,Nr HP petrol pump, BRTS Rd, Simada Gam, Parvat Patiya, Surat, Gujarat 395010
          </p><br/>

          <div>
            <div>📞 <span className='fw-bold'>Phone:</span> +91 63530 54080</div><br/>
            <div>📞<span className='fw-bold'> WhatsApp:</span> +91 89804 50755</div><br/>
            <div>✉️ <span className='fw-bold'>Email:</span> rmvinternational9@gmail.com</div><br/>
          </div>
        </div>
      </div>
    </div>

    
    
    </>
  )
}

export default Hoc(Contact)