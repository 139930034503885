import React from 'react';
import Hoc from './Hoc';
import Whychose from './Whychose';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <>
      {/* <!-- slide start --> */}

      <div className='mttt'>
      <div className='slideImg'>
        
<img src={require('../assets/images/banner.jpg')} className='img-fluid imgshodow' alt="" />

<div className='content ' data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1500">

<div className='text_bg ms-3'>
  <h1>A Leading Global Player In The Metal Industries</h1>

  <h5 className='mt-5  px-5'>
  Welcome to RMV International, your trusted partner in the world of prisicion machining, Metal Fabrication, Die Casting and innovation. With decades of experience and a commitment to excellence, we offer a wide range of metal solutions tailored to meet your unique needs
  </h5>
</div>


</div>

        </div>
      </div>
  
   


      {/* <!-- slide end --> */}
      

      <div className='mt-5 '>

      <div className="our_pro" 
       data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1500">
          <h2>Our Service</h2>
        </div>


<div className='container text-center'>

<div className='row ms-0 me-0  ms-0' >

<div className='col-lg-2 col-md-12    ms-0'>
<Link to={'/precision'} className='text nav-link'>
<div className='our_service'>

<img src={require('../assets/images/precision_main.jpg')}className='img-fluid img-service object-fit-cover' alt="" />

<h6 className='py-4 text-center'>Precision Machining</h6>

</div>

</Link>

</div>

<div className='col-lg-2 col-md-12    ms-0'>
<Link to={'/metal'} className='text-decoration-none nav-link'>
<div className='our_service'>

<img src={require('../assets/images/sheet metal main.jpg')}className='img-fluid img-service object-fit-cover' alt="" />

<h6 className='py-4 text-center'>Sheet Metal Fabrication</h6>

</div>


</Link>

</div>

<div className='col-lg-2 col-md-12    ms-0'>
<Link to={'/stamping'} className='nav-link'>
<div className='our_service'>

<img src={require('../assets/images/stamping bg.jpg')}className='img-fluid img-service object-fit-cover' alt="" />

<h6 className='py-4 text-center'>Stamping & Punching</h6>

</div>
</Link>

</div>
<div className='col-lg-2 col-md-12    ms-0'>
<Link to={'/laser'} className='nav-link'>
<div className='our_service'>

<img src={require('../assets/images/laserbg.jpg')}className='img-fluid img-service object-fit-cover' alt="" />

<h6 className='py-4 text-center'>Lacer Cutting</h6>

</div>
</Link>

</div>
<div className='col-lg-2 col-md-12    ms-0'>
<Link to={'/casting'} className='nav-link'>
<div className='our_service'>

<img src={require('../assets/images/casting Main.jpg')}className='img-fluid img-service object-fit-cover' alt="" />

<h6 className='py-4 text-center'>Casting</h6>

</div>
</Link>

</div>
<div className='col-lg-2 col-md-12    ms-0'>
<Link to={'/finishing'} className='nav-link'>
<div className='our_service'>

<img src={require('../assets/images/finishing main.jpg')}className='img-fluid img-service object-fit-cover' alt="" />

<h6 className='py-4 text-center'>Finishing Processes</h6>

</div>
</Link>

</div>


</div>
</div>
      </div>



      {/* content */}

<div className='container'>

  <div className='text-center contenthead'>
    <h4>Supporting the Worldwide needs of industries with ultra-precise
CNC Machining and Manufacturing</h4>
  </div>

  <div className='contentsub mt-4'>
    <p>RMV International is a precision machining and manufacturing company that provides a wide range of CNC Manufacturing services as components from raw stock, casting and forging with Precision machining, Micromachining, 5-axis machining, Swiss machining, RAM/Sink/EDM, wire EDM, CNC lathes/turning, Sheet Metal Fabrication. They have a team of experienced machinists and engineers who are committed to providing high-quality parts on time and within budget.</p>

    <p className='mt-3'>RMV International uses state-of-the-art CNC machines and equipment to produce high quality parts with tight tolerances.  They can machine a variety of materials, including steel, aluminum, brass, and plastic.  RMV International also has the expertise and experience to machine complex micro parts with precision down to 0.001mm.  They can supply parts for various industries including aerospace, automobile, medical-hotel, oil-gas, energy, infrastructure.</p>
  </div>


</div>

<div className="bg_img">

        <div className='contenthead text-center content_h pt-4'>
               
               <h4>Industries We Serve</h4>

               <h2>Supporting the nationwide needs of industries
with ultra-precise CNC machine servicing</h2>


<h5>At RMV International, our commitment to excellence in manufacturing and
engineering extends across various industries. We proudly serve:</h5>

        </div>


<div className='row m-0  py-5'>

{/* first */}
<div className='col-lg-8 content_h'>

<div className='p-lg-5 p-0'>
<h5 className='text-white'>Automotive: Delivering precision components for the automotive sector, ensuring quality, durability, and performance.</h5>

<h5 className='mt-4 text-white'>
Aerospace: Providing specialized parts that meet the rigorous standards of the aerospace industry, contributing to safety and reliability.
</h5>
</div>


</div>

<div className='col-lg-2 '>

  <div className='img_sercice mt-lg-0 mt-3'>
    <img src={require('../assets/images/automobile head.avif')} className='img-fluid ' alt="" />

    <h4 className='text-center mt-2'>Automobile </h4>
  </div>

</div>
<div className='col-lg-2  '>

  <div className='img_sercice  mt-lg-0 mt-3'>
    <img src={require('../assets/images/aerospace.jpg')} className='img-fluid ' alt="" />

    <h4 className='text-center mt-2'>Aerospace</h4>
  </div>

</div>


{/* second */}

<div className='col-lg-2 '>

  <div className='img_sercice  mt-lg-0 mt-3 '>
    <img src={require('../assets/images/oil-gas.jpg')} className='img-fluid ' alt="" />

    <h5 className='text-center mt-2'>Oil & Gas</h5>
  </div>

</div>
<div className='col-lg-2 '>

  <div className='img_sercice  mt-lg-0 mt-3 '>
    <img src={require('../assets/images/power.jpg')} className='img-fluid w-100' alt="" />

    <h4 className='text-center mt-2'>Power Plant</h4>
  </div>

</div>


<div className='col-lg-8 content_h'>

<div className='p-lg-4 p-0'>
<h5 className='text-white'>Oil & Gas: Machining parts play a critical role in the oil and gas industry, contributing to various aspects of exploration, production, refining, and distribution</h5>

<h5 className='mt-4 text-white'>
Power Plant: power plants often use machining components in various capacities. Machined parts are crucial in the manufacturing and maintenance of power generation equipment.
</h5>
</div>


</div>


{/* third */}

<div className='col-lg-8 content_h'>

<div className='p-lg-4 p-0'>
<h5 className='text-white'>Medical Devices: Crafting critical components for medical devices, adhering to strict regulations for patient well-being.</h5>

<h5 className='mt-4 text-white'>
Energy and Utilities: Supporting energy and utility companies with components that enhance efficiency and reliability in power generation and distribution.
</h5>
</div>


</div>

<div className='col-lg-2'>

  <div className='img_sercice  mt-lg-0 mt-3 '>
    <img src={require('../assets/images/medical.jpg')} className='img-fluid mt-2' alt="" />

    <h4 className='text-center mt-4'>Medical</h4>
  </div>

</div>
<div className='col-lg-2'>

  <div className='img_sercice  mt-lg-0 mt-3 '>
    <img src={require('../assets/images/energy.jpg')} className='img-fluid ' alt="" />

    <h4 className='text-center mt-2'>Energy</h4>
  </div>

</div>



{/* four */}

<div className='col-lg-2 mt-3'>

  <div className='img_sercice  mt-lg-0 mt-3 '>
    <img src={require('../assets/images/defance.jpg')} className='img-fluid ' alt="" />

    <h4 className='text-center mt-2'>Defence</h4>
  </div>

</div>
<div className='col-lg-2 mt-3'>

  <div className='img_sercice  mt-lg-0 mt-3 '>
    <img src={require('../assets/images/infrastructure.jpg')} className='img-fluid ' alt="" />

    <h4 className='text-center mt-2'>Infrastructure</h4>
  </div>

</div>


<div className='col-lg-8 content_h'>

<div className='p-lg-4 p-0'>
<h5 className='text-white'>Defence: Precision machining is indispensable to the defense industry, which plays an important role in the design, manufacture and continuous improvement of military equipment and systems</h5>

<h5 className='mt-4 text-white'>
Infrastructure: Machining parts form the backbone of infrastructure, enabling transportation, energy, water, communication, building, manufacturing, and healthcare systems worldwide.
</h5>
</div>


</div>


{/* five */}

{/* <div className='col-lg-8 content_h'>

<div className='p-lg-3 p-0'>
<h5 className='text-white'>Automotive: Delivering precision components for the automotive sector, ensuring quality, durability, and performance.</h5>

<h5 className='mt-4 text-white'>
Aerospace: Providing specialized parts that meet the rigorous standards of the aerospace industry, contributing to safety and reliability.
</h5>
</div>


</div> */}

{/* <div className='col-lg-2 '>

  <div className='img_sercice'>
    <img src={require('../assets/images/hardware main.jpg')} className='img-fluid ' alt="" />

    <h4 className='text-center mt-2'>Hardware Items</h4>
  </div>

</div>
<div className='col-lg-2 '>

  <div className='img_sercice'>
    <img src={require('../assets/images/hardware main.jpg')} className='img-fluid ' alt="" />

    <h4 className='text-center mt-2'>Hardware Items</h4>
  </div>

</div> */}


</div>
           
       </div>



       {/* why chose start */}

<Whychose/>


<div className='py-4 container '>
   <h1 className='borderbotoom text-center mx-auto'>A Place where we help you
to build your Dream Products</h1>
</div>

    </>
  );
}

export default Hoc(Home);
