import React from 'react'
import Hoc from './Hoc'
import Slider from "react-slick";

function Lasercutting() {
  
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    swipe: true, // touch swipe
    arrows: false, //nex and previous button off
    pauseOnHover: false, // Set to false to continue autoplay on hover
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
    <div className='bg_laser d-flex justify-content-center align-items-center'>

<div className=' tex' data-aos="zoom-in"
  data-aos-delay="50"
  data-aos-duration="1500">
    <h4 className='text-white container text-center'>
    RMV International is a Gujarat based leader in precision laser cutting services & metal fabrication work. Our team of cutting professionals is devoted to providing the highest levels of sales, production & customer support 
    </h4>
</div>

</div>

{/* conten and img */}

<div className='container'>

    <div className='row'>
 
 <div className='col-lg-6'>

    <div className='pb-3 p-3'>
        {/* <h2 className='fs-4 bor p-2'>PRECISION MACHINING SERVICES :</h2> */}
    </div>
    <h2 className='fw-bold borderbotoom'>Laser Cutting</h2>

    <div className='pt-0 content_precision'>
<p>
RMV International Sheet Metal offers advanced laser cutting solutions to create custom shapes that meet any production demand, from low-volume prototypes to high-volume production runs.
</p>
<p>
Our advanced laser technology is CNC-controlled by our qualified and experienced operators, allowing us to precisely cut the most complex and intricate shapes within the tightest tolerances.
</p>

    </div>

    <div className='content_precision mt-5'>
 <h4 className='borderbotoom'>Benefits of Laser Cutting
</h4>

<p >
Laser cutting has a number of benefits over conventional mechanical cutting systems. One of the notable advantages of this technique is its exceptional accuracy - fully automated, CNC-programmed movement ensures repeatable tolerances are consistently met every cycle. Furthermore, the precision of the laser tool does not diminish since the beam is not subject to physical wear. The lack of a physical cutting tool also reduces the risk of surface contamination, making laser cutting ideal for sanitary and medical applications.
</p>



<p className='mt-5'>No matter the complexity or scale of your project, we have the expertise and capability to deliver precise components with exceptional precision and efficiency.</p>

    </div>



 </div>


<div className='col-lg-6'>

   <div className='p-5'>
   <img src={require('../assets/images/laser-cutting content.jpg')} className='img-fluid' alt="" />
   </div>

</div>

<div className='content_precision'>
 

<p className='mt-5'>
The gas flow dynamics of high-powered lasers also guarantees a clean cut through the entire thickness of the metal, minimizing manufacturing defects such as burrs. There is no limit to the types of shapes that laser cutters can produce, and laser cutting eliminates the need for tooling - keeping costs low and turnaround time quick. CNC machines can be programmed to create shapes of almost any complexity for a wide range of applications.
</p>



<p className='mt-4'>Laser cutting enables the nesting of shapes, allowing us to minimize waste by getting the most out of the raw material. This lean manufacturing process leads to significant material cost savings which can be passed on to our customers.</p>

    </div>

    <div className='content_precision mt-5 '>
 <h4 className='fw-bold'>Laser Cutting System Capabilities:
</h4>

<p className='mt-4'>
At RMV International, the extremely small heat-affected zone of our state-of-the-art laser systems enable us to cut plate thickness ranging from ultra-thin to ¼” without warping. The lasers deliver a minimal cutting kerf of 1/16” on holes and 0.008” for cuts, thus maximizing cutting precision and accuracy within a ± 0.005” tolerance.
</p>



<p className='mt-4'>With maximum power outputs of up to 2000 watts, we can cut through a variety of materials with dimensions up to 4 feet in width and 4 feet in length. Some of the metals we typically work with include but are not limited to carbon steel, stainless steel, and aluminum.</p>

    </div>


    </div>




</div>

<div className='mb-5'>
<Slider {...settings}>



<div className='p-3'>

<img src={require('../assets/serviceimg/Laser cutting/images (1).jpeg')} style={{height:'230px'}} className='img-fluid w-100 object-fit-cover' alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/Laser cutting/download (3).jpeg')} style={{height:'230px'}} className='img-fluid w-100 object-fit-cover' alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/Laser cutting/images (2).jpeg')} style={{height:'230px'}} className='img-fluid w-100 object-fit-cover' alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/Laser cutting/images (3).jpeg')} style={{height:'230px'}} className='img-fluid w-100 object-fit-cover' alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/Laser cutting/images.jpeg')} style={{height:'230px'}} className='img-fluid w-100 object-fit-cover' alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/Laser cutting/images (4).jpeg')} style={{height:'230px'}} className='img-fluid w-100 object-fit-cover' alt="" />

</div>



</Slider>
</div>
    

<div className='container content_precision'>
  <h3 className='borderbotoom'>Laser Cutting Services from RMV International </h3>

  <p>RMV International offers a host of fabrication services to supplement our laser cutting process, including welding, finishing, and assembly. We aim to be a one-stop shop for customers seeking convenient, turnkey sheet metal fabrication solutions. For unique services not currently available in-house, we partner with our local trusted network of industry suppliers and professionals-simplifying the supply chain, and saving our customers considerable time and money.
</p>

<p className='mt-3'>
RMV International is committed to product quality and customer satisfaction and works tirelessly to ensure that all orders are completed on time, without sacrificing quality. Most orders can be completed within 2 to 5 weeks, with lead times varying according to specific project requirements. We also pride ourselves in fast turnaround time, especially for prototypes.

</p>

<p className='mt-3'>
Are you interested in RMV internationall’s precision laser cutting services for your next project? Be sure to contact our support team to get started.
</p>
</div>

    </>
  )
}

export default Hoc(Lasercutting)