import React from 'react'
import Hoc from './Hoc'
import Slider from 'react-slick';

function Medical() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        swipe: true, // touch swipe
        arrows: false, //nex and previous button off
        pauseOnHover: false, // Set to false to continue autoplay on hover
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    
  return (
    <>
    <div className='bg_medical d-flex justify-content-center align-items-center'>

<div className=' tex' data-aos="zoom-in"
  data-aos-delay="50"
  data-aos-duration="1500">
    <h2 className='text-white'>
    Medical :
    </h2>
</div>

</div>
    

    <div className='container my-4'>
     
 <div className='row'>

    <div className='col-lg-6'>

    <h4 className='borderbotoom fw-bold'>Precision CNC Machining Services for Custom Medical Components</h4>


<div className='content_precision mt-5'>

  <p>The medical industry relies on precisely crafted products to ensure the well-being of individuals. When it comes to manufacturing parts or components for life-saving medical devices, there is no room for mistakes. RMV International  is a CNC machining expert serving various sectors within the medical industry. From manufacturing medical parts to promoting prototype development, we deliver highly accurate components to fulfill diverse applications.</p>

  <p>
  With cutting-edge technology, highly skilled technicians, and the latest equipment, we make it possible to produce top-notch precision in our machined parts and exceed other CNC machine shops. Our expertise, quality, and concentration on details have gained the trust of a multitude of medical device manufacturers, developers, and designers. Whether it is the rapid prototyping of single-part prototypes or the mass production of over 10,000 products, we are here to support your needs.
  </p>




</div>

    </div>

    <div className='col-lg-6'>
  <img src={require('../assets/images/medical content.jpg')} className='img-fluid mt-5 ms-lg-5 m-0' alt="" />
    </div>

 </div>
 

  
  <div className='row m-0 my-5'>

    <div className='col-lg-12'>

    <div className='content_precision'>
    <p>CNC Machining in Medical Parts Prototyping and Manufacturing</p>


<p className='mt-4'>

<span className='fw-bold'>1.Orthopedic Implants:</span> CNC machining is commonly used to manufacture orthopedic implants such as hip implants, knee implants, and spinal implants. These implants require precise dimensions and surface finishes to ensure proper fit and function within the human body.


</p>
<p className='mt-4'>

<span className='fw-bold'>2. Surgical Instruments:</span>  Many surgical instruments, including forceps, scalpels, retractors, and drills, are manufactured using CNC machining. These instruments must be highly precise and durable to ensure accurate and safe surgical procedures.



</p>
<p className='mt-4'>
<span className='fw-bold'>3.Dental Components:</span>  CNC machining is used to fabricate various dental components, including crowns, bridges, dental implants, and prosthetics. These components require high precision and biocompatible materials to ensure compatibility with the oral environment.
</p>
<p className='mt-4'>
<span className='fw-bold'>4.Prosthetic Devices:</span>   CNC machining is utilized in the production of prosthetic devices such as artificial limbs and joints. These devices must be customized to fit the individual patient's anatomy and require precise machining to achieve the desired functionality and comfort.

</p>
<p className='mt-4'>
<span className='fw-bold'>5.Diagnostic Equipment:</span>   CNC parts are used in the manufacturing of diagnostic equipment such as MRI machines, CT scanners, and ultrasound devices. These machines consist of complex components and housings that require precise machining to maintain accuracy and reliability in medical imaging.


</p>
<p className='mt-4'>
<span className='fw-bold'>6.Implantable Devices:</span>   Some implantable medical devices, such as pacemakers, defibrillators, and neurostimulators, contain components manufactured using CNC machining. These devices must meet stringent quality standards and biocompatibility requirements to ensure safety and effectiveness in the human body.



</p>
<p className='mt-4'>
<span className='fw-bold'>7.Surgical Robotics:</span>   CNC machining plays a crucial role in the manufacturing of components for surgical robotic systems used in minimally invasive surgery. These systems require high precision and reliability to enable precise control and manipulation during surgical procedures.


</p>
<p className='mt-4'>
<span className='fw-bold'>8. Lab Equipment and Tools:</span>   CNC parts are used in the production of various laboratory equipment and tools used in medical research and testing. This includes components for centrifuges, microscopes, spectrophotometers, and analytical instruments.

</p>

<p>Overall, CNC machining offers the medical industry the ability to produce highly precise and customized components and devices that are essential for diagnosis, treatment, and patient care. The use of CNC parts contributes to advancements in medical technology, improving patient outcomes and enhancing the quality of healthcare.</p>

    </div>

    </div>



  </div>


    </div>


    
    <div className='mb-5'>
<Slider {...settings}>



<div className='p-3'>

<img src={require('../assets/industry/medical & Pharma/commercial-industrial-10.png')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/medical & Pharma/commercial-industrial-2.png')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/medical & Pharma/commercial-industrial-3.png')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/medical & Pharma/commercial-industrial-4.png')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/medical & Pharma/commercial-industrial-5.png')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/medical & Pharma/commercial-industrial-6.png')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/medical & Pharma/commercial-industrial-8.png')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/medical & Pharma/commercial-industrial-9.png')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>





</Slider>
</div>
    
    
    </>
  )
}

export default Hoc(Medical)