import React from 'react'
import Slider from 'react-slick';
import Hoc from './Hoc';

function Automobile() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        swipe: true, // touch swipe
        arrows: false, //nex and previous button off
        pauseOnHover: false, // Set to false to continue autoplay on hover
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
  return (
   <>

<div className='bg_automobile d-flex justify-content-center align-items-center'>

<div className=' tex' data-aos="zoom-in"
  data-aos-delay="50"
  data-aos-duration="1500">
    <h2 className='text-white'>
    Automobile :
    </h2>
</div>

</div>


<div className='container py-5'>
 
 <h2 className='borderbotoom fw-bold'>India CNC Machining Manufacturer of OEM Automobile  Parts</h2>

 <div className='content_precision mt-5'>
 <p>The development of the automotive and motorcycle industry relies on automotive parts processing, motorcycle parts production, and automobile parts prototype making. Moving forward, the automobile industry is expected to be focused on electric energy-saving, self-driving technology, and shared internet capabilities, with constant updates each year. With global economic integration, international auto R&amp;D companies and manufacturers are streamlining their supply chaiIndia and controlling production costs. As a result, certain aspects of auto parts procurement, auto parts machining, motorcycle parts production, and automobile parts prototyping are being outsourced to India. In the future, India will offer more support services to the global automotive industry. Since 2013, <strong>RMV International</strong> has been providing customers with <strong>customized automobile and <a > machining</a> and prototyping services</strong>, assisting in the advancement of the automotive and motorcycle industry.</p>

 <p>When it comes to custom <strong>automotive parts processing</strong>, <strong>motorcycle parts production</strong>, and <strong>auto parts prototype making</strong>, multiple processes are usually required. This typically involves <a >CNC machining</a>, as well as boring, milling, and grinding processing to achieve tight tolerance up to +/- 0.005mm and <a >surface finish</a> up to Ra 0.8.</p>
 </div>


<h2 className='borderbotoom fw-bold'>Materials and Surface Finishes for Auto Parts</h2>

<p className='my-4'>We offer a multitude of materials and post-processing operations for your choice to help you get the desired automobile parts, auto and motorcycle prototypes.</p>

<h3>Materials</h3>

<p className='my-4'>We work with a variety of premium materials. Metals, plastics, wood, ceramic, composites, and other custom materials are available upon request.</p>
 

 <ul>
    <li>Metal materials that are commonly used include: aluminum alloys, zinc alloys, brass, carbide, copper, magnesium alloys, stainless steel, steel, cold-rolled steel, and galvanized steel.</li>
    <li className='mt-3'>
    Plastic materials are available including: ABS, PC, POM, PMMA, PEEK, carbon fiber, PET, PP, PA, and other materials customized to suit different processes and requirements.

    </li>
 </ul>
 <hr />

 <div className='content_prisicion'>
 <h2 className='borderbotoom fw-bold'>Sheet Metal Parts For Automobile</h2>

<p>Laser cutting is used to manufacture many parts of an automobile, including:
Body parts: Bumpers, body panels, chassis, doors, and lift gates
Engine components: Engine parts, fuel injectors, fuel filters, and alternators
Interior panels: Dashboards, seats, brackets, and seating frames
Other parts: Heat shields, interior panels, and exhaust systems</p>




<h2 className='borderbotoom fw-bold'>Pricision Machining Part for Aoutomobile</h2>

<div className='row'>
       <div className='col-lg-4'>
         <ul>
            <li>Fittings and adapters
</li>
            <li>Precision screws, nuts, and bolts
</li>
            <li>	Stems</li>
            <li>Precision pins
</li>
            <li>	Precision spacers
</li>
            <li>	Valve retainers
</li>
<li>Pistons
</li>
         </ul>
       </div>
        <div className='col-lg-4'>
            <ul>
                <li>Titanium valve keepers
</li>
                <li>Brake system components
</li>
                <li>Dowel pins
</li>
                <li>Splined shafts
</li>
                <li>Bushings
</li>
<li>Timing covers</li>
            </ul>
        </div>
</div>


 </div>


 <div className='content_prisicion'>

<h2 className='borderbotoom fw-bold'>Surface Finishes</h2>

<p className='my-4'>
Post-processing operations are often required after the machining of motorcycle and automobile parts. These processes significantly enhance the specific properties of these machined parts, including both their functionality and visual appeal. Consider the following common surface finishes that are available for the motorcycle and car parts we provide:
</p>

<div className='row'>
       <div className='col-lg-4'>
         <ul>
            <li>Anodizing</li>
            <li>Passivization</li>
            <li>	Painting</li>
            <li>Polishing</li>
            <li>	Sandblasting</li>
         </ul>
       </div>
        <div className='col-lg-4'>
            <ul>
                <li>Electroplating</li>
                <li>Powder Coating</li>
                <li>Insert Installation</li>
                <li>Heat Treatment</li>
                <li>Screen Printing</li>
            </ul>
        </div>
</div>


 </div>

</div>


    
<div className='mb-5'>
<Slider {...settings}>



<div className='p-3'>

<img src={require('../assets/industry/automobile/AdobeStock_446100659.webp')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/automobile/ezgif.com-gif-maker-60 - Copy.webp')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/automobile/11.jpg')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/automobile/image-3.png')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/automobile/pngtree-various-metal-parts-of-a-motor-vehicle-picture-image_3475618 - Copy.jpg')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/industry/automobile/samochody_1.jpeg - Copy.webp')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>



</Slider>
</div>



<div className='content_pricision container my-5'>
<h2>Ready to Get Custom Precision Parts for Your Automobiles ?</h2>

<p className='mt-3'>With over 10 years of experience serving the automotive and motorcycle industry, RMV International is a reliable provider of OEM services. We specialize in the manufacturing of high-quality car components and motorcycle accessories that exceed our customers' expectations. Our extensive knowledge of the manufacturing process allows us to deliver custom precision components with tolerances as tight as +/- 0.010 inches. Whether it's short-run production, volume production, or prototyping, we have the capability to meet the high demands of the auto and motorcycle industries. Partner with us for unparalleled quality and service you can rely on.</p>

</div>




   
   </>
  )
}

export default Hoc(Automobile)