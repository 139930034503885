import React from 'react'
import '../assets/css/service.css'
import Hoc from './Hoc'
import Slider from "react-slick";

function Precision() {

    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        swipe: true, // touch swipe
        arrows: false, //nex and previous button off
        pauseOnHover: false, // Set to false to continue autoplay on hover
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

  return (
    <>
    {/* bacground image */}
    <div className='bg_Precision d-flex justify-content-center align-items-center'>

    <div className=' tex' data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1500">
        <h3 className='text-white text-center container'>
        Generally, the type of CNC mills is defined according to their number of axes. With more axes, the ability to manufacture complex parts and create cutting angles is enhanced and strengthened.
        </h3>
    </div>

    </div>


{/* content */}

<div className='container'>

    <div className='row'>
 
 <div className='col-lg-6'>

    <div className=''>
        <h2 className='borderbotoom fs-4 fw-bold'>PRECISION MACHINING SERVICES </h2>
    </div>

    <div className='pt-0 content_precision'>
<p>
We delivering precision machining solutions that meet the highest standards of quality and reliability. With a relentless commitment to accuracy and excellence, we cater to diverse industries and applications, providing custom machining services tailored to your unique specifications.

</p>

    </div>

    <div className='content_precision'>
 <h4 className='fw-bold'>Our Services :
</h4>

<p>
Utilizing state-of-the-art CNC machining technology and a team of skilled professionals, we offer a comprehensive range of machining services including:
</p>

<ul className=''>
    <li> CNC Turning </li>
    <li> CNC Milling 
 </li>
    <li>Grinding</li>
    <li>Prototyping
</li>
<li>Surface Finishing
</li>
<li>Assembly
</li>
</ul>

<p>No matter the complexity or scale of your project, we have the expertise and capability to deliver precise components with exceptional precision and efficiency.</p>

    </div>



 </div>


<div className='col-lg-6'>

   <div className='p-0 p-lg-5'>
   <img src={require('../assets/images/precision_service.jpg')} className='img-fluid' style={{height:'500px'}} alt="" />
   </div>

</div>


    </div>




</div>



<div className='container text content_precision'>

    <h4>Why Choose RMV International for Precision Machine Parts Manufacturer & Supplier in India?</h4>

<p>
<span className='fw-bold d-block'>Modern Manufacturing Establishments:</span> RMV International, we use state-of-the-art manufacturing facilities and cutting-edge technology to create precision machine parts that are incredibly accurate and efficient. Our state-of-the-art infrastructure guarantees that we can satisfy our clients most exacting demands.
</p>

<p>
<span className='fw-bold d-block'>Wide Selection of Precision Machine Parts:</span> We are a top precision machine parts manufacturer & supplier in India, and we provide a wide selection of goods made to meet the needs of different markets. From custom machine parts to CNC turned components, we have the know-how to provide solutions that meet your unique requirements.
</p>

<p>
<span className='fw-bold d-block'>Assurance of Quality:</span> At RMV Interntional, quality is of utmost importance. Throughout the manufacturing process, we maintain strict quality control procedures to ensure the accuracy, robustness, and dependability of our machine parts. Clients from a variety of industries have come to trust us because of our dedication to quality.
</p>

<p>
<span className='fw-bold d-block'>Skilled Group of Engineers:</span> We have years of experience and knowledge among our team of talented engineers. Our experts work hard to guarantee that every precision machine part fulfills the highest standards from design to production. We take great satisfaction in our ability to provide solutions that go above and beyond.
</p>

<p >

<span className='fw-bold d-block'>Customization Options:</span> We provide adaptable solutions to match your particular needs because we understand that every project has different requirements. Our engineering team collaborates closely with customers to comprehend their needs and provide precision machine parts that are custom-made and seamlessly integrate into their applications.
</p>

<p>
Choose RMV International when you're looking for precision machine parts manufacturer & supplier in India. That live up to the highest standards of quality, dependability, and accuracy. Modern facilities, a staff of skilled experts, and an unwavering dedication to excellence make us the preferred manufacturer and supplier of precision machine parts in India. Get in touch with us right now to discuss your needs and take advantage of RMV International advantages.
</p>

</div>

<div className='mb-5'>
<Slider {...settings}>



<div className='p-3'>

<img src={require('../assets/serviceimg/pricision machining/1-feature.jpg')} className='img-fluid w-100 ' style={{height:'190px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/pricision machining/istockphoto-1268263987-612x612.jpg')} className='img-fluid w-100' alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/pricision machining/images (2).jpeg')} className='img-fluid w-100' alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/pricision machining/images.jpeg')} className='img-fluid w-100' alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/pricision machining/images (1).jpeg')} className='img-fluid w-100' alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/pricision machining/download.jpeg')} style={{height:'190px'}} className='img-fluid w-100' alt="" />

</div>



</Slider>
</div>



{/* tabs */}

<div className='main_finish'>
  
   <div className='container py-5'>      
     
    






     <div class="mainbg mt-5 container h-100" id="gallery">

<div class="text-center">
  {/* <h1>Our Finishes</h1> */}
<hr/>

</div>

<div class="tabs">

  <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist" data-aos="zoom-in"
  data-aos-easing="linear"
  data-aos-duration="1300">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-Anodizing" type="button" role="tab" aria-controls="pills-Anodizing" aria-selected="true">Aluminum Alloys</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="pills-Bead-tab" data-bs-toggle="pill" data-bs-target="#pills-Bead" type="button" role="tab" aria-controls="pills-Bead" aria-selected="false">Copper Alloys</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="pills-Powder-tab" data-bs-toggle="pill" data-bs-target="#pills-Powder" type="button" role="tab" aria-controls="pills-Powder" aria-selected="false">Brass Alloys</button>
    </li>
     <li class="nav-item" role="presentation">
      <button class="nav-link" id="pills-Polishing-tab1" data-bs-toggle="pill" data-bs-target="#pills-Polishing" type="button" role="tab" aria-controls="pills-Polishing" aria-selected="false">Steel Alloys</button>
    </li> 
     <li class="nav-item" role="presentation">
      <button class="nav-link" id="pills-Brushing-tab1" data-bs-toggle="pill" data-bs-target="#pills-Brushing" type="button" role="tab" aria-controls="pills-Brushing" aria-selected="false">Titanium Alloys</button>
    </li> 
    
  
  </ul>
  <div class="tab-content" id="pills-tabContent" >
    <div class="tab-pane fade show active" id="pills-Anodizing" role="tabpanel" aria-labelledby="pills-Anodizing-tab" tabindex="0">
      <div class="row ">
        
        <div className='col-lg-5'>
           
    <div className='my-5 mx-5'>
        <img src={require('../assets/pricision/aluminum.jpg')} className='img-fluid' alt="" />
    </div>

        </div>
         <div className='col-lg-1'></div>
        <div className='col-lg-5 my-5 '>

        <div className='content_pricision text-center'>

          <p>Aluminum 6061</p>
          <p>Aluminum 5052</p>
          <p>Aluminum 2024</p>
          <p>Aluminum 6063</p>
          <p>Aluminum 7050</p>
          <p>Aluminum 7075</p>
          <p>Aluminum MIC-6</p>
          <p>Learn more about aluminum for CNC machining.</p>



        </div>

</div>

      </div>
    </div>
    <div class="tab-pane fade" id="pills-Bead" role="tabpanel" aria-labelledby="pills-Bead-tab" tabindex="0" >
    <div class="row ">
        
        <div className='col-lg-5'>
           
    <div className='my-5 mx-5'>
        <img src={require('../assets/pricision/coper.jpg')} className='img-fluid' alt="" />
    </div>

        </div>
         <div className='col-lg-1'></div>
        <div className='col-lg-5 my-5 '>

        <div className='content_pricision text-center'>

          <p>Copper 101</p>
          <p>Copper C110</p>
          <p>Learn more about copper for CNC machining.</p>
          



        </div>

</div>

      </div>
    </div>
    <div class="tab-pane fade" id="pills-Powder" role="tabpanel" aria-labelledby="pills-Powder-tab" tabindex="0">
   
    <div class="row ">
        
        <div className='col-lg-5'>
           
    <div className='my-5 mx-5'>
        <img src={require('../assets/pricision/brass.jpg')} className='img-fluid' alt="" />
    </div>

        </div>
         <div className='col-lg-1'></div>
        <div className='col-lg-5 my-5 '>

        <div className='content_pricision text-center'>

          <p>Copper 260</p>
          <p>Copper 360</p>
          <p>Learn more about copper for CNC machining.</p>
          



        </div>

</div>

      </div>
    </div>


   


    <div class="tab-pane fade" id="pills-Polishing" role="tabpanel" aria-labelledby="pills-Polishing-tab1" tabindex="0">.
    
    <div class="row ">
        
        <div className='col-lg-5'>
           
    <div className='my-5 mx-5'>
        <img src={require('../assets/pricision/steel.jpg')} className='img-fluid' alt="" />
    </div>

        </div>
         <div className='col-lg-1'></div>
        <div className='col-lg-5 my-5 '>

        <div className='content_pricision text-center'>

          <div className='d-flex'>
            <div>
            <p>Steel 1018</p>
          <p>Steel 1215</p>
          <p>Steel 4130</p>
          <p>Steel 4140</p>
            </div>
            <div className='ms-5'>
            <p>Steel 4140PH</p>
          <p>Steel 4340</p>
          <p>Steel A36</p>
            </div>
          </div>

        
          
          



        </div>

</div>

      </div>
    
    
    .</div>
    <div class="tab-pane fade" id="pills-Brushing" role="tabpanel" aria-labelledby="pills-Brushing-tab1" tabindex="0">.
    
    <div class="row ">
        
        <div className='col-lg-5'>
           
    <div className='my-3 mx-5'>
        <img src={require('../assets/pricision/titanium.jpg')} className='img-fluid' alt="" />
    </div>

        </div>
         <div className='col-lg-1'></div>
        <div className='col-lg-5 my-5 '>

        <div className='content_pricision text-center'>

          <p>Titanium (Grade 1)</p>
          <p>Titanium (Grade 2)</p>
          <p>Titanium (Grade 5).</p>
          



        </div>

</div>

      </div>
    
    
    .</div>

   
   
  </div>

</div>




</div>



   </div>



  </div>
    
    </>
  )
}

export default Hoc(Precision)