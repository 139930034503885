import React from "react";
import Hoc from "./Hoc";

function About() {
  return (
    <>
      <div className="container">
        <div>
          <h2>Who We Are</h2>
        </div>
        <hr />

        <div className="row">
          <div className="col-lg-6">
            <p>
              <strong>
                <span style={{ color: "#faa61a" }}>—"</span>One of the Best
                Manufacturers of Metal Part Machining for Prototyping &amp;
                Production in India<span style={{ color: "#faa61a" }}>"</span>
              </strong>
            </p>

            <div>
              <img
                src={require("../assets/images/aboutus.jpg")}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>

          <div className="col-lg-6 mt-4">
            <p className="my-5">
              RMV International is a{" "}
              <strong>rapid prototyping manufacturer</strong> with the
              “ISO9001:2015” certification. Headquartered in India, RMV International
             has more than 10 years of{" "}
              <strong>custom CNC machining </strong>experience. We specialize in{" "}
              <a>
                <strong>custom CNC machining services</strong>
              </a>{" "}
              from design, rapid prototyping, and complex parts geometry to low
              or large volume production. Committed to producing high-quality
              machined parts under your budget and lead time demand, can take your concepts or designs to reality production
              in just days with our specialization, quality, and craftsmanship.
            </p>
            <p>
              RMV International has fully guaranteed the quality of CNC products
              and customer satisfaction in strict accordance with the provisions
              of the ISO standards in the regulations of management rules. We
              partner with organizations around the world to craft precisely
              machined parts in a variety of certificated materials, including
              various plastics and metals. Products we created have been widely
              used in aerospace, medical, automotive, communication, mechanical,
              electronic, intelligent equipment, toys, and other industries.
            </p>
          </div>
        </div>
      </div>

      {/* about count */}

      <div className="my-4">
        <div className="ab_bg py-4">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-3 orderstart mt-lg-0 mt-3">
                <div className="row ">
                  <div className="col-lg-4 mt-2">
                    <img
                      src={require("../assets/images/ab1.png")}
                      className="img-fluid"
                      style={{ width: "70px" }}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-8 mt-3">
                    <h5 className="text-white fw-bold">
                      2M <sup>+</sup>
                    </h5>
                    <p className=" text-white">
                      <strong>Parts Delivered</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 orderstart mt-lg-0 mt-3">
                <div className="row ">
                  <div className="col-lg-4 mt-2">
                    <img
                      src={require("../assets/images/ab2.png")}
                      className="img-fluid"
                      style={{ width: "70px" }}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-8 mt-3">
                    <h5 className="text-white fw-bold">
                      50 <sup>+</sup>
                    </h5>
                    <p className=" text-white">
                      <strong>Materials & Finishes</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 orderstart mt-lg-0 mt-3">
                <div className="row ">
                  <div className="col-lg-4 mt-2">
                    <img
                      src={require("../assets/images/ab3.png")}
                      className="img-fluid"
                      style={{ width: "70px" }}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-8 mt-3">
                    <h5 className="text-white fw-bold">
                      17 <sup>+</sup>
                    </h5>
                    <p className=" text-white">
                      <strong>Countries Shipped</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 ">
                <div className="row ">
                  <div className="col-lg-4 mt-2">
                    <img
                      src={require("../assets/images/ab4.png")}
                      className="img-fluid"
                      style={{ width: "70px" }}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-8 mt-3">
                    <h5 className="text-white fw-bold">
                      250 <sup>+</sup>
                    </h5>
                    <p className=" text-white">
                      <strong>Customers Served</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

{/* process start */}

<div className="container my-5">

<div className="row">
<div className="col-lg-6"> 

<div className="our_pro">
    <h2 className="">Quality Assurance</h2>
</div>

<div class="fl-rich-text">
<p><strong>ISO Certified Manufacturer</strong></p>
<p>Our approach strictly adheres to the ISO 9001-certified quality management system. We rely on a set of standardized production processes and work instructions, and employ cutting-edge testing equipment. This allows us to meticulously measure and inspect every stage of production, guaranteeing that your project meets rigorous quality standards. Our specialized manufacturing with quality control guarantees excellent service for customers worldwide.</p>
<p><strong>Quality control of production&nbsp;</strong></p>
<p>As your loyal supplier, we have strict manufacturing quality control in all manufacturing processes. To ensure these control processes can be implemented in place, we schedule the following steps:</p>
<ul>
<li>Part Design Checking</li>
<li>Mold Design Control</li>
<li>Raw material inspection</li>
<li>Visual inspection</li>
<li>FAI – First Article Inspection</li>
<li>PPAP – Production Part Approval Process</li>
<li>Mold Steel Hardness Inspection</li>
<li>Mold Electrodes Inspection</li>
<li>Mold Core and Cavity Steel Dimension Inspection</li>
<li>Mold Pre-Assembly Inspection</li>
<li>Mold Trial Report and Samples Inspection</li>
<li>Pre-Shipment Final Inspection</li>
<li>Export Product Package Inspection</li>
</ul>
</div>

</div>

<div className="col-lg-6">

<div className="our_pro">

<h2>RMV International</h2>
</div>

<img src={require('../assets/images/process about.png')} className="img-fluid" alt="" />

</div>
</div>

</div>


    </>
  );
}

export default Hoc(About);
