import React, { useEffect, useState } from "react";
import "../assets/css/Header.css";
import { Link } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";
import { FaSortDown } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";


function Header() {
  const [navBackground, setNavBackground] = useState("#ffffff");


  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentPosition = window.pageYOffset;
  //     if (currentPosition > 50) {
  //       setNavBackground("#ffffff");
  //     } else {
  //       setNavBackground("transparent");
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);


  return (
    <>
      {/* <section className="heder-main bg-black text-white py-2  ">
        <div className="hed-title d-flex justify-content-center justify-content-center">
          <div>RMV INTERNTIONAL</div>
        </div>
      </section> */}

      <header
        className="w-100 position-sticky top-0 z-3 quaheader_design "
        id="headermaincss"
        style={{
          backgroundColor: navBackground,
          transition: "all 0.3s ease-in-out",
        }}
      >
        <nav className="z-2">
          <div className="d-flex align-items-center">
            <button
              className="btn toggle-menu text-black fw-bold fs-1 p-0 border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasWithBothOptions"
              aria-controls="offcanvasWithBothOptions"
            >
              <IoMdMenu />
            </button>
            <div style={{ width: "90px", marginTop:'10px' }} className=" d-lg-none d-block text-center mx-auto">
              <Link to={"/"}>
                <img
                  src={require("../assets/images/rr.jpg")}
                  alt=""
                  className="img-fluid w-100"
                />
              </Link>
            </div>
          </div>
          <div className="d-none d-lg-block">
          <ul className="main-menu container ms-3 d-flex justify-content-between align-items-center teko">
            <div style={{ width: "" }} className="d-flex">
              <Link to={"/"}className="d-flex" >
                <img
                  src={require("../assets/images/rr.jpg")}
                  style={{width:'60px',height:"80px"}}
                  alt=""
                  className="img-fluid w-100 object-fit-contain "
                />
                
              </Link>
                <span className="fs-2 pt-3 ms-5 poppins-thin">R<span style={{color:'#faa61a'}}>M</span>V International</span>

            </div>

       
        <div className="align-item-center">
        <li>
              <Link to={"/"} className="hoverheader_menu">
                Home
              </Link>
            </li>
          <li className="dropdown ">
              <Link >Industries <span className="text-black"><FaSortDown style={{marginTop:'-10px'}} /></span></Link>
              <ul className="dropdown-menu teko">
               
               
                <li>
                  <Link to={"/aerospace"}>Aerospace</Link>
                </li>
                <li>
                  <Link to={"/automobile"}>Automobile</Link>
                </li>
                <li>
                  <Link to={"/medical"}>Medical</Link>
                </li>
                <li>
                  <Link to={"/oil"}>Oil & Gas</Link>
                </li>
                <li>
                  <Link to={"/hardware"}> Hardware Items</Link>
                </li>
                
            
              </ul>
            </li>
            <li className="dropdown">
              <Link to={""}>Service <span className="text-black"><FaSortDown style={{marginTop:'-10px'}} /></span></Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to={"/precision"}>Precision Machining</Link>
                </li>
                <li className="dropdwon">
                <Link to={""} href="#" className="d-flex align-content-center justify-content-between align-items-center">Sheet Metal<IoMdArrowDropright /></Link>
                <ul className="dropdown-menu">
<li>
  <Link to={"/stamping"}>Stamping & Punhing</Link>
</li>
<li>
  <Link to={"/laser"}>Laser Cutting</Link>
</li>
<li>
  <Link to={"/metal"}>Metal Fabrication</Link>
</li>
                </ul>
                </li>
                <li>
                  <Link to={"/casting"}>Casting</Link>
                </li>
                <li>
                  <Link to={"/finishing"}>Finishing Processes</Link>
                </li>
              </ul>
            </li>

            <li className="">
              <Link to={"/gallery"} className="hoverheader_menu"> Gallery</Link>
             
            </li>
            <li>
              <Link to={"/about"} className="hoverheader_menu">
                About
              </Link>
            </li>
            <li>
              <Link to={"/contact"} className="hoverheader_menu">
                Contact
              </Link>
            </li>
          </div>

         
       
          </ul>
          </div>
        </nav>



        {/*  media sixze header */}
        <div
          className="headoff offcanvas offcanvas-start"
          data-bs-scroll="true"
          tabindex="-1"
          id="offcanvasWithBothOptions"
          aria-labelledby="offcanvasWithBothOptionsLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
              <Link to={"/"}>
                <img
                  src={require("../assets/images/rr.jpg")}
                  className="img-fluid"
                  style={{ width: "80px" }}
                  alt=""
                />
              </Link>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
          <div className="border-bottom py-3 ps-4 ">
                <Link to={"/"} className="text-decoration-none text-black">
                  Home
                </Link>
              </div>
            <div className="accordion accordion-flush" id="accordionFlushExample">
              
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                   Industries
                  </button>
                </h2>
                
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    
                    <div className="py-2">
                      <Link to={"/aerospace"} className="offcanvasdesign">
                      Aerospace
                      </Link>
                    </div>
                    
                    <div className="py-2">
                      <Link to={"/automobile"} className="offcanvasdesign">
                      Automobile
                      </Link>
                    </div>
                    
                    <div className="py-2">
                      <Link to={"/medical"} className="offcanvasdesign">
                      Medical
                      </Link>
                    </div>
                    
                   
                    <div className="py-2">
                      <Link to={"/oil"} className="offcanvasdesign">
                      Oil & Gas
                      </Link>
                    </div>
                    <div className="py-2">
                      <Link to={"/hardware"} className="offcanvasdesign">
                      Hardware Items
                      </Link>
                    </div>
                   
                  </div>
                </div>
              </div>
              
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsecollection"
                    aria-expanded="false"
                    aria-controls="flush-collapsecollection"
                  >
                   Service
                  </button>
                </h2>
                <div
                  id="flush-collapsecollection"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="py-2 pt-0">
                      <Link to={"/precision"} className="offcanvasdesign">
                      Precision Machining
                      </Link>
                    </div>
                    <div class="accordion accordion-flush" id="nestedAccordionOne">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-nestedCollapseOne" aria-expanded="false" aria-controls="flush-nestedCollapseOne">
              <div className="py-2"> <Link to={""} className="offcanvasdesign">Sheet Metal</Link></div>
              </button>
            </h2>
            <div id="flush-nestedCollapseOne" class="accordion-collapse collapse" data-bs-parent="#nestedAccordionOne">
              <div class="accordion-body">
               <div className="py-1"><Link to={"/stamping"} className="offcanvasdesign">Stamping & Punching</Link></div>
               <div className="py-1"><Link to={"/laser"} className="offcanvasdesign">Laser cutting</Link></div>
               <div className="py-1"><Link to={"/metal"} className="offcanvasdesign">Metal Fabrication</Link></div>
              </div>
            </div>
          </div>
        </div>
                    <div className="py-2">
                      <Link to={"/casting"} className="offcanvasdesign">
                      Casting
                      </Link>
                    </div>
                    <div className="py-2">
                    <Link to={"/finishing"} className="offcanvasdesign">
                      Finishing Processes
                      </Link>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="border-bottom py-3 ps-4 ">
                <Link to={"/gallery"} className="text-decoration-none text-black">
                  Gallery
                </Link>
              </div>
              <div className="border-bottom py-3 ps-4 ">
                <Link to={"/about"} className="text-decoration-none text-black">
                  About
                </Link>
              </div>
            
              <div className="border-bottom py-3 ps-4 ">
                <Link to={"/contact"} className="text-decoration-none text-black">
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      

   
    </>
  );
}

export default Header;
