
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import { BrowserRouter, Route, Routes } from 'react-router-dom/dist';
import './assets/css/style.css'
import './assets/css/industry.css'
import HardwareItem from './Component/HardwareItem';
import ScrollToTop from './Component/ScrolltoTop';
import Home from './Component/Home';
import Precision from './Component/Precision';
import Lasercutting from './Component/Lasercutting';
import Stamping from './Component/Stamping';
import MetalFabrication from './Component/MetalFabrication';
import Casting from './Component/Casting';
import Contact from './Component/Contact';
import Finishing from './Component/Finishing';
import About from './Component/About';
import Aerospace from './Component/Aerospace';
import Automobile from './Component/Automobile';
import Medical from './Component/Medical';
import OilGas from './Component/OilGas';
import Gallery from './Component/Gallery';


function App() {
  return (
    <div className="App">
     <BrowserRouter>
<ScrollToTop/>
     <Routes>

 <Route path='/' element={<Home/>}/>
 <Route path='/hardware' element={<HardwareItem/>}/>
 <Route path='/precision' element={<Precision/>}/>
 <Route path='/laser' element={<Lasercutting/>}/>
 <Route path='/stamping' element={<Stamping/>}/>
 <Route path='/metal' element={<MetalFabrication/>}/>
 <Route path='/casting' element={<Casting/>}/>
 <Route path='/finishing' element={<Finishing/>}/>
 <Route path='/contact' element={<Contact/>}/>
 <Route path='/about' element={<About/>}/>
 <Route path='/aerospace' element={<Aerospace/>}/>
 <Route path='/automobile' element={<Automobile/>}/>
 <Route path='/medical' element={<Medical/>}/>
 <Route path='/oil' element={<OilGas/>}/>
 <Route path='/gallery' element={<Gallery/>}/>





     </Routes>

     
     
     
     
     
     </BrowserRouter>
    </div>
  );
}

export default App;
