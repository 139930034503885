import React from 'react'
import Hoc from './Hoc'

function Finishing() {
  return (
  <>

<div className='content_precision container my-5'>
  <h2 className='borderbotoom fw-bold'>Finishing Processes</h2>
  <p><span className='fw-bold'>Precision Perfected:</span> Advanced Finishing Processes for Machined Components</p>
<p>
Metal parts surface finishing refers to the process of improving the surface of metal components to achieve desired characteristics such as smoothness, cleanliness, corrosion resistance, and aesthetics. There are various methods for finishing metal surfaces, including grinding, polishing, buffing, coating, and plating. Each method is chosen based on the specific requirements of the part and the desired outcome. It is important to consider factors such as material type, application, and budget when selecting the appropriate surface finishing technique for metal parts.
</p>




</div>



  
  <div className='main_finish'>
  
   <div className='container py-5'>      
     
     <div className='content_precision'>
        <h2 className='borderbotoom fw-bold'>Available Sheet Metal Finishes</h2>

        <p className='mt-4'>The mechanical properties and performance of sheet metal fabricated parts or components can be enhanced through surface treatment. If you want to improve the chemical resistance, appearance attractiveness, and hardness of your sheet metal parts after machining, you can choose from the following finishing options suitable for sheet metal manufacturing.</p>
     </div>






     <div className="mainbg mt-5 container h-100" id="gallery">

<div className="text-center">
  {/* <h1>Our Finishes</h1> */}
<hr/>

</div>

<div className="tabs">

  <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist" data-aos="zoom-in"
  data-aos-easing="linear"
  data-aos-duration="1300">
    <li className="nav-item" role="presentation">
      <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-Anodizing" type="button" role="tab" aria-controls="pills-Anodizing" aria-selected="true">Anodizing</button>
    </li>
    <li className="nav-item" role="presentation">
      <button className="nav-link" id="pills-Bead-tab" data-bs-toggle="pill" data-bs-target="#pills-Bead" type="button" role="tab" aria-controls="pills-Bead" aria-selected="false">Bead Blasted</button>
    </li>
    <li className="nav-item" role="presentation">
      <button className="nav-link" id="pills-Powder-tab" data-bs-toggle="pill" data-bs-target="#pills-Powder" type="button" role="tab" aria-controls="pills-Powder" aria-selected="false">Powder Coat</button>
    </li>
    <li className="nav-item" role="presentation">
      <button className="nav-link" id="pills-Electroplating-tab" data-bs-toggle="pill" data-bs-target="#pills-Electroplating" type="button" role="tab" aria-controls="pills-Electroplating" aria-selected="false">Electroplating</button>
    </li>
     <li className="nav-item" role="presentation">
      <button className="nav-link" id="pills-Polishing-tab1" data-bs-toggle="pill" data-bs-target="#pills-Polishing" type="button" role="tab" aria-controls="pills-Polishing" aria-selected="false">Polishing</button>
    </li> 
     <li className="nav-item" role="presentation">
      <button className="nav-link" id="pills-Brushing-tab1" data-bs-toggle="pill" data-bs-target="#pills-Brushing" type="button" role="tab" aria-controls="pills-Brushing" aria-selected="false">Brushing</button>
    </li> 
  
  </ul>
  <div className="tab-content" id="pills-tabContent" >
    <div className="tab-pane fade show active" id="pills-Anodizing" role="tabpanel" aria-labelledby="pills-Anodizing-tab" tabindex="0">
      <div className="row ">
        
        <div className='col-lg-3 ms-0 ms-lg-3'>
           
    <div className='my-5'>
        <img src={require('../assets/finishing/anodizing.jpg')} className='img-fluid' alt="" />
    </div>

        </div>
         {/* <div className='col-lg-1'></div> */}
        <div className='col-lg-8 my-5 '>

<table className="table table-sm">
   
<thead>
<tr>
<th className='p-3'>Materials</th>
<th className='p-3'>Color</th>
<th className='p-3'>Texture</th>
</tr>
</thead>
<tbody>
 <tr className='p-5'>
    <td className='p-3'>Aluminum</td>
    <td className='p-3'>Clear, black, grey, red, blue, gold.</td>
    <td className='p-3'>Smooth, matte finish</td>
    </tr> 
 <tr>
</tr> 



</tbody>

</table>

</div>

      </div>
    </div>
    <div className="tab-pane fade" id="pills-Bead" role="tabpanel" aria-labelledby="pills-Bead-tab" tabindex="0" >
    <div className="row " >
        
        <div className='col-lg-3 ms-0 ms-lg-3'>
           
    <div className='my-5'>
        <img src={require('../assets/finishing/bead.jpg')} className='img-fluid' alt="" />
    </div>

        </div>
         {/* <div className='col-lg-1'></div> */}
        <div className='col-lg-8 my-5 '>

<table className="table table-responsive table-sm">
   
<thead>
<tr>
<th className='p-3'>Materials</th>
<th className='p-3'>Color</th>
<th className='p-3'>Texture</th>
</tr>
</thead>
<tbody>
 <tr>
    <td className='p-3'>ABS, Aluminum, Brass, Stainless Steel, Steel</td>
    <td className='p-3'>n/a</td>
    <td className='p-3'>Matte</td>
    </tr> 
 <tr>
</tr>  



</tbody>

</table>

</div>

      </div>
    </div>
    <div className="tab-pane fade" id="pills-Powder" role="tabpanel" aria-labelledby="pills-Powder-tab" tabindex="0">
   
    <div className="row " >
        
        <div className='col-lg-3 ms-0 ms-lg-3'>
           
    <div className='my-5'>
        <img src={require('../assets/finishing/powdercoat.jpg')} className='img-fluid' alt="" />
    </div>

        </div>
         {/* <div className='col-lg-1'></div> */}
        <div className='col-lg-8 my-5 '>

<table className="table table-responsive table-sm">
   
<thead>
<tr>
<th className='p-3'>Materials</th>
<th className='p-3'>Color</th>
<th className='p-3'>Texture</th>
</tr>
</thead>
<tbody>
 <tr>
    <td className='p-3'>Aluminum, Stainless Steel, Steel</td>
    <td className='p-3'>Black, any RAL code or Pantone number</td>
    <td className='p-3'>Gloss or semi-gloss</td>
    </tr> 
 <tr>
</tr> 



</tbody>

</table>

</div>

      </div>
    </div>


    <div className="tab-pane fade" id="pills-Electroplating" role="tabpanel" aria-labelledby="pills-Electroplating-tab" tabindex="0">
   
    <div className="row " >
        
        <div className='col-lg-3 ms-0 ms-lg-3'>
           
    <div className='my-5'>
        <img src={require('../assets/finishing/electroplating.jpg')} className='img-fluid' alt="" />
    </div>

        </div>
         {/* <div className='col-lg-1'></div> */}
        <div className='col-lg-8 my-5 '>

<table className="table table-responsive table-sm">
   
<thead>
<tr>
<th className='p-3'>Materials</th>
<th className='p-3'>Color</th>
<th className='p-3'>Texture</th>
</tr>
</thead>
<tbody>
 <tr>
    <td className='p-3'>Aluminum, steel, stainless steel</td>
    <td className='p-3'>n/a</td>
    <td className='p-3'>Smooth, glossy finish</td>
    </tr> 
 <tr>
</tr> 



</tbody>

</table>

</div>

      </div>
    </div>


    <div className="tab-pane fade" id="pills-Polishing" role="tabpanel" aria-labelledby="pills-Polishing-tab1" tabindex="0">.
    
    <div className="row " >
        
        <div className='col-lg-3 ms-0 ms-lg-3'>
           
    <div className='my-5'>
        <img src={require('../assets/finishing/polishing.jpg')} className='img-fluid' alt="" />
    </div>

        </div>
         {/* <div className='col-lg-1'></div> */}
        <div className='col-lg-8 my-5 '>

<table className="table table-responsive table-sm">
   
<thead>
<tr>
<th className='p-3'>Materials</th>
<th className='p-3'>Color</th>
<th className='p-3'>Texture</th>
</tr>
</thead>
<tbody>
 <tr>
    <td className='p-3'>Aluminum, Brass, Stainless Steel, Steel</td>
    <td className='p-3'>n/a</td>
    <td className='p-3'>	
Glossy</td>
    </tr> 
 <tr>
</tr> 



</tbody>

</table>

</div>

      </div>
    
    
    .</div>
    <div className="tab-pane fade" id="pills-Brushing" role="tabpanel" aria-labelledby="pills-Brushing-tab1" tabindex="0">.
    
    <div className="row " >
        
        <div className='col-lg-3 ms-0 ms-lg-3'>
           
    <div className='my-5'>
        <img src={require('../assets/finishing/brushing.jpg')} className='img-fluid' alt="" />
    </div>

        </div>
         {/* <div className='col-lg-1'></div> */}
        <div className='col-lg-8 my-5 '>

<table className="table table-responsive table-sm">
   
<thead>
<tr>
<th className='p-3'>Materials</th>
<th className='p-3'>Color</th>
<th className='p-3'>Texture</th>
</tr>
</thead>
<tbody>
 <tr>
    <td className='p-3'>ABS, Aluminum, Brass, Stainless Steel, Steel</td>
    <td className='p-3'>n/a</td>
    <td className='p-3'>Satin</td>
    </tr> 
 <tr>
</tr> 



</tbody>

</table>

</div>

      </div>
    
    
    </div>
   
  </div>

</div>




</div>

   </div>

  </div>



<div className='container my-3'>

  <div className='content_precision'>
    <h2 className='borderbotoom fw-bold'>Pricision Machining Surface Finishes</h2>

    <p className='mt-4'>Different surface finishing is available for milling parts, such as painting, anodizing, and chroming. This will enhance function and aesthetic appearance greatly.</p>
  </div>

<div className='row'>

 <div className='col-lg-6 order-4 order-lg-3'>

 <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      As machined
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Without any surface treatment processing methods, the parts will leave visible tool marks and burrs, which can be completed quickly and cost-saving.</div>
    </div>
  </div>
  <div className="accordion-item mt-3">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      Bead Blasted
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">A surface treatment process to obtain a certain degree of cleanliness and different roughness on the surface of the workpiece. The surface after sandblasting is dull and matte.</div>
    </div>
  </div>
  <div className="accordion-item mt-3">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      Anodizing
      </button>
    </h2>
    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Many colors（such as black, red, blue, etc.）are available. Divided into Type II (normal) and Type III (hard), Type III is usually thicker than Type II and more corrosion resistant.</div>
    </div>
  </div>
  <div className="accordion-item mt-3">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
      Powder Coat
      </button>
    </h2>
    <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">The powdered paint is sprayed onto the surface of the workpiece by electrostatic spraying to form a powdery coating, and then the powder layer is cured by heating and baking to level it into a uniform film layer. It is usually used for appearance parts, and a variety of colors is available.</div>
    </div>
  </div>
  <div className="accordion-item mt-3">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
      Custom
      </button>
    </h2>
    <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Request an instant RFQ and we will offer you the appropriate surface finish for your project if you still do not see the finish you are looking for.</div>
    </div>
  </div>
</div>

 </div>

<div className='col-lg-6 order-3 order-lg-4'> 


<img src={require('../assets/pricision/finishing accordian.jpg')} className='img-fluid' alt="" />


</div>


</div>




</div>



<div className='container'>
<div className='row'>
<div className='col-lg-6'>
  <div className='content_precision'>
<p>RMV International specializes in a wide variety of high-quality metal finishing services to enhance the physical and mechanical properties of fabricated metal components.</p>

<p>Drawing on over four decades of experience, our knowledgeable staff can achieve almost any type of finish for virtually any application or environment.
</p>
<p>
To further support our customers’ needs, we offer a broad range of capabilities including precision laser cutting, CNC machining, metal stamping, and welding. These in-house services enable us to provide single-source solutions for our customers’ diverse metal fabrication needs.

</p>
<p>For unique projects with highly specialized requirements or specifications, RMV also partners with local certified suppliers and industry professionals to handle customized jobs.</p>
  </div>
</div>

<div className='col-lg-6'>
<div className='mt-3'>
  <img src={require('../assets/images/finishing content.jpg')} className='img-fluid' alt="" />
</div>
</div>

</div>


<div className='content_pricision'>

<h3 className='borderbotoom'>Benefits of Metal Finishing</h3>

<p>
Metal finishing alters the surface of a metal workpiece to achieve specific characteristics. Following the initial metal component’s fabrication, finishing operations typically enhance one or more of the following properties:

</p>

<ul>
  <li>
  Adhesiveness
  </li>
  <li>Electrical conductivity
</li>
<li> Corrosion resistance
 </li>
 <li>Wear or abrasion resistance
</li>
<li>
Chemical resistance

</li>
<li>
Frictional resistance

</li>
<li>
Solderability

</li>
<li>
Weldability

</li>
<li>
Aesthetics
</li>
<li>
Dimensional accuracy

</li>
<li>
Surface quality
</li>
</ul>

</div>

</div>


  
  </>
  )
}

export default Hoc(Finishing)