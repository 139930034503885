import React from 'react'

function Whychose() {
  return (
    <>
    
    <div className='container mt-5 mb-5'>
          
          <div data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1500">
            <h2 className='text-center why_contet'>Why Choose Us!</h2>
          </div>

        <div className='row mt-5'>

<div className='col-lg-4' data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1500">

<div className='text-center why'>

    <img src={require('../assets/images/why 1.png')} className='img-fluid' alt="" />

    <div className="infobox-content mt-5">
               <h2 className="title">High precision work </h2>
                                                                                   </div>
                                                                                   <div class="text-center">
							<p>A company is only good as its people. We have achieved excellence at this part by building the team which is technically sound and empowers our company culture and propels us further.</p>						</div>

</div>

</div>
<div className='col-lg-4' data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1500">

<div className='text-center why'>

    <img src={require('../assets/images/why 2.png')} className='img-fluid' alt="" />

    <div className="infobox-content mt-5">
               <h2 className="title">Less Turn-Around Time </h2>
                                                                                   </div>
                                                                                   <div class="text-center">
							<p>We provide solutions for complex  manufacturing challenges and create innovative and advanced solutions that revolutionize Manufacturing industry.</p>						</div>

</div>

</div>
<div className='col-lg-4'data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1500">

<div className='text-center why'>

    <img src={require('../assets/images/why 3.png')} className='img-fluid' alt="" />

    <div className="infobox-content mt-5">
               <h2 className="title">Quality Assurance </h2>
                                                                                   </div>
                                                                                   <div class="text-center">
							<p>We only hire great people who strive to push their ideas into fruition by outmuscling and outhustling the competition.</p>						</div>

</div>

</div>


        </div>





    </div>
    
    </>
  )
}

export default Whychose