import React from 'react'
import Slider from "react-slick";
import Hoc from './Hoc';
function Casting() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        swipe: true, // touch swipe
        arrows: false, //nex and previous button off
        pauseOnHover: false, // Set to false to continue autoplay on hover
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    
  return (
    <>
    
    <div className='bg_casting d-flex justify-content-center align-items-center'>

<div className=' tex' data-aos="zoom-in"
  data-aos-delay="50"
  data-aos-duration="1500">
    <h4 className='text-white container text-center'>
    Whether it is for large-scale production or small batches, pressure die-casting is a reliable and efficient solution for creating complex metal components that drive innovation and success across various industries

    </h4>
</div>

</div>

<div className='container'>

    <div className='row mt-5'>

  <div className='col-lg-6'>
  
  <div className='content_precision'>


<h3 className='fw-bold borderbotoom'>Pressure Die-Casting</h3>

<p className='mt-3'>
Pressure die-casting is a highly efficient and widely used manufacturing process that offers numerous benefits for producing intricate and complex metal parts.
</p>
<p className='mt-3'>
This process involves injecting molten metal, typically aluminum or zinc alloys, into a reusable steel mold called a die. The molten metal is forced under high pressure into the die cavity, ensuring precise replication of the mold’s shape and details. 
</p>
<p className='mt-3'>
One of the advantages of pressure die-casting is its ability to achieve rapid production cycles. The process allows for high-volume production runs with quick turnaround times, making it ideal for industries such as automotive, aerospace, electronics, and consumer goods.
</p>

  </div>

  </div>

  <div className='col-lg-6'>

    <div className='mt-5'>
        <img src={require('../assets/images/casting content.png')} className='img-fluid' alt="" />
    </div>



  </div>


  <div className='content_precision'>

<p>
The fast cycle times of pressure die-casting enable manufacturers to meet demanding production schedules, reduce time-to-market, and respond effectively to customer needs.
</p>
<p className='mt-4'>
Moreover, the repeatability and consistency of the die-casting process ensure that each part produced meets the required specifications, contributing to overall product quality.
</p>
<p className='mt-4'>
The origins of pressure die casting can be traced back to the mid-19th century when the process was first patented in the United States and Europe. Initially, the process was used to cast simple lead and tin alloys. However, with advancements in technology and materials, pressure die casting evolved into a reliable and widely used method for producing various metal components.
</p>


<h3 className='mt-5 borderbotoom fw-bold'>
Materials Used in Pressure Die Casting:
</h3>

<p className='mt-4'><span className='fw-bold'>Aluminum:</span>Aluminum and its alloys, such as A380, A383, and ADC12, are the most popular materials for pressure die casting due to their excellent castability, lightweight, and good mechanical properties.</p>
<p className='mt-4'><span className='fw-bold'>Zinc:</span>Zinc alloys, like Zamak 3, Zamak 5, and ZA-8, are commonly used for applications requiring intricate details and thin walls. Zinc has good fluidity, high impact strength, and is cost-effective.</p>
<p className='mt-4'><span className='fw-bold'>Magnesium:</span> Magnesium alloys are lightweight and offer excellent strength-to-weight ratios, making them suitable for applications in the aerospace and automotive industries</p>
<p className='mt-4'><span className='fw-bold'>Copper:</span>Copper-based alloys are used for specialized applications that require high electrical and thermal conductivity.
Brass: Brass, a combination of copper and zinc, is used for parts that require both strength and corrosion resistance.</p>
<p className='mt-4'><span className='fw-bold'>Lead and Tin:</span>Lead and tin alloys are used for specific applications in industries such as plumbing and electronics.</p>

  </div>

    </div>



</div>

    
    <div className='mb-5'>
<Slider {...settings}>



<div className='p-3'>

<img src={require('../assets/serviceimg/die casting/4-groove-pulley-wheel-500x500-500x500.jpg')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/die casting/85v925f.jpg')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/die casting/B1044610445.jpg')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/die casting/H42c3030d79fd4f7b93c9e518559a23aem.jpg')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/die casting/OEM-ODM-Agricultural-Cast-Iron-Gn12-Tractor-Parts-Clutch-Cam.jpg')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/die casting/cast-iron-automotive-parts-500x500.jpg')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/die casting/csm_lm-sulzer-nsp-pumpengehaeuse-laufrad-offen-main-image_ba9f5d2968.png')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/die casting/gearbox-casting-part-500x500 - Copy.jpg')} className='img-fluid w-100 object-fit-contain' style={{height:'250px'}} alt="" />

</div>




</Slider>
</div>
    </>
  )
}

export default Hoc(Casting)