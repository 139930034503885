import React from 'react'
import Hoc from './Hoc'
import Slider from 'react-slick'

function Aerospace() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        swipe: true, // touch swipe
        arrows: false, //nex and previous button off
        pauseOnHover: false, // Set to false to continue autoplay on hover
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    
  return (
    <>

<div className='bg_aerospace d-flex justify-content-center align-items-center'>

<div className=' tex' data-aos="zoom-in"
  data-aos-delay="50"
  data-aos-duration="1500">
    <h2 className='text-white'>
    Aerospace 
    </h2>
</div>

</div>


<div className='container'>

<div className='row'>

<div className='col-lg-6'>
<h3 className='borderbotoom fw-bold'>AEROSPACE MACHINE SERVICING FACILITY</h3>

<h4 className='mt-3'>
Precision aerospace components are essential for the safe and reliable operation of aircraft.

</h4>

<div className='content_precision mt-2'>
<p>RMV International is a leading provider of CNC machining services in India. The company has a long history of providing high-quality parts and components to the aerospace industry. RMV Intenational services include:</p>


<ul>
    <li>CNC machining of aerospace components</li>
<li>Precision metalworking</li>
<li>Aerospace tooling</li>
<li>Aerospace fabrication</li>
</ul>
<p>RMV International CNC machining capabilities are second to none. The company uses the latest CNC machines to produce high-precision parts with tight tolerances. RMV International metalworking expertise allows the company to shape and form metal into the desired components. RMV International tooling services ensure that the company’s customers have the tools and fixtures they need to manufacture their products. RMV International fabrication services allow the company to assemble and weld the individual components into a finished product.</p>
</div>
</div>

<div className='col-lg-6'>

<div className='ps-lg-5 ps-0 mt-5'>
<img src={require('../assets/images/aresospace content.jpg')} alt="" className='img-fluid' />
</div>

</div>

</div>



<div className='content_precision mt-3'>

<div className='text-center d-flex justify-content-center'>
<h1 className='fs-3 borderbotoom'>CNC Machined Parts in the Aerospace Industry
</h1>
</div>

<p className='mt-3'><span className='fw-bold'>Introduction:</span>CNC machining technology has revolutionized the aerospace industry, enabling the production of high-precision components that are vital for aircraft and spacecraft. From engine components to structural elements, CNC machined parts play a crucial role in ensuring the performance, safety, and reliability of aerospace systems.
</p>
<p className='mt-3'><span className='fw-bold'>Engine Components:</span>CNC machining is extensively utilized in the production of engine components such as turbine blades, compressor parts, and housings. These parts require intricate geometries and high tolerances to withstand extreme temperatures and stresses encountered during operation.

</p>
<p className='mt-3'><span className='fw-bold'>Control Surfaces :</span>CNC machining is employed in the fabrication of control surfaces such as flaps, ailerons, and rudders. These parts demand precise shaping and surface finishing to maintain aerodynamic efficiency and flight stability.
</p>
<p className='mt-3'><span className='fw-bold'>Avionics Housings :</span>Housings and enclosures for avionics systems are crafted using CNC machining techniques. These components safeguard navigation equipment, communication devices, and instrumentation from environmental factors while ensuring accessibility for maintenance and upgrades.

</p>
<p className='mt-3'><span className='fw-bold'>Interior Components:</span>CNC machined parts are also integral to interior components such as seat frames, overhead bins, and cabin fixtures. Lightweight materials like aluminum and composites are often used to enhance fuel efficiency without compromising passenger comfort.
</p>

</div>




</div>



<div className='mb-5'>
<Slider {...settings}>



<div className='p-3'>

<div className='p-5'>
<img src={require('../assets/industry/aerospace/15.webp')} style={{height:'200px'}} className='img-fluid w-100 object-fit-contain ' alt="" />
</div>

</div>
<div className='p-3'>

<div className='p-5'>
<img src={require('../assets/industry/aerospace/4.webp')} style={{height:'200px'}} className='img-fluid w-100 object-fit-contain ' alt="" />
</div>

</div>
<div className='p-3'>

<div className='p-5'>
<img src={require('../assets/industry/aerospace/aerospace-10.webp')} style={{height:'200px'}} className='img-fluid w-100 object-fit-contain ' alt="" />
</div>

</div>
<div className='p-3'>

<div className='p-5'>
<img src={require('../assets/industry/aerospace/aerospace-11.webp')} style={{height:'200px'}} className='img-fluid w-100 object-fit-contain ' alt="" />
</div>
</div>
<div className='p-3'>

<div className='p-5'>
<img src={require('../assets/industry/aerospace/aerospace-12.png')} style={{height:'200px'}} className='img-fluid w-100 object-fit-contain ' alt="" />
</div>

</div>

<div className='p-3'>

<div className='p-5'>
<img src={require('../assets/industry/aerospace/aerospace-17.webp')} style={{height:'230px',padding:'10px'}} className='img-fluid w-100 object-fit-contain ' alt="" />
</div>

</div>
<div className='p-3'>

<div className='p-5'>
<img src={require('../assets/industry/aerospace/aerospace-2.webp')} style={{height:'200px'}} className='img-fluid w-100 object-fit-contain ' alt="" />
</div>

</div>
<div className='p-3'>

<div className='p-5'>
<img src={require('../assets/industry/aerospace/aerospace-4.webp')} style={{height:'200px'}} className='img-fluid w-100 object-fit-contain ' alt="" />
</div>

</div>
<div className='p-3'>

<div className='p-5'>
<img src={require('../assets/industry/aerospace/aerospace-6.webp')} style={{height:'200px'}} className='img-fluid w-100 object-fit-contain ' alt="" />
</div>

</div>


</Slider>
</div>

    
    </>
  )
}

export default Hoc(Aerospace)