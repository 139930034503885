import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
function Footer() {
  return (
    <>
      <section className="footer">
        <footer>
          <div className="container">
            <div className="row mobilelogo">
              <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 col-xxl-9">
                <div className="row">
                  <div className="col-6  col-md-4 col-lg-3 ">
                    <div className="footerlinks">
                      <h5>Industries</h5>
                      <ul id="menu-footer-products" className="footermenu">
                        
                        <li
                          id="menu-item-1518"
                          className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1518"
                        >
                          <Link to={"/aerospace"}>Aerospace</Link>
                        </li>
                        <li
                          id="menu-item-1515"
                          className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1515"
                        >
                          <Link to={"/automobile"}>Automobile</Link>
                        </li>
                        <li
                          id="menu-item-1517"
                          className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1517"
                        >
                          <Link to={"/medical"}>Medical</Link>
                        </li>
                        <li
                          id="menu-item-1516"
                          className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1516"
                        >
                          <Link to={"/oil"}>Oil & Gas</Link>
                        </li>
                        <li
                          id="menu-item-1514"
                          className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1514"
                        >
                          <Link to={"/hardware"}>HardWare Items</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="  col-lg-4 ">
                    <div className="footerlinks">
                      <h5>Service</h5>
                      <ul id="menu-footer-company" className="footermenu">
                        <li
                          id="menu-item-325"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-325"
                        >
                          <Link to={"/precision"}>Precision Machining</Link>
                        </li>
                        <li
                          id="menu-item-327"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-327"
                        >
                          <Link to={""}>Sheet Metel Fabrication</Link>
                        </li>
                        <li
                          id="menu-item-328"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-328"
                        >
                          <Link to={"/casting"}>Casting</Link>
                        </li>
                        <li
                          id="menu-item-329"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-329"
                        >
                          <Link to={"/finishing"}>Finishing Processes</Link>
                        </li>
                        <li
                          id="menu-item-1604"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1604"
                        >
                          <Link to={"/metal"}>Stamping & Punching</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="footerlinks">
                    <h5>Quick Links</h5>
                    <ul id="menu-quick-footer" className="footermenu">
                      <li id="menu-item-355" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-355">
                        <a href="/">Downloads</a>
                      </li>
                      <li id="menu-item-356" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-356">
                        <a href="">Contact Us</a>
                      </li>
                      
                    </ul>
                  </div>
                </div> */}
                  <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="footerlinks ">
                      <h5>Follow Us On</h5>
                      <h6 className="text-white">rmvinternational9@gmail.com </h6>
                      <div className="footermenu sociallinks">
                        <div class="d-flex justify-content-start align-items-center mt-3 sclmd">
                          <a href="https://www.instagram.com/rmvinternational/" className="px-2 mx-1 nav-link text-white">
                            <i className="">
                            <FaInstagram />
                            </i>
                          </a>
                          <a
                            href="https://www.linkedin.com/in/meet-vaghasiya-331524272/"
                            target="_blank"
                            className="mx-1"
                          >
                            <i className="">
                              <FaLinkedin />
                            </i>
                          </a>
                          <a
                            href="https://www.youtube.com/@RMVInternational"
                            target="_blank"
                            className="px-2 mx-1"
                          >

                            <i
                              className="fa-brands fa-instagram"
                             
                            >
                              <FaYoutube />
                            </i>
                          </a>
                          <a
                            href="https://www.indiamart.com/rmv-international/"
                            target="_blank"
                            className="px-2 mx-1"
                          >

                            <img src={require('../assets/gallery/INDIAMART.NS.png')} className="img-fluid" alt="" />
                          </a>
                          <a
                            href=""
                            className="px-2 mx-1" 
                          >
                            <i
                              className="fa-brands fa-youtube"
                             
                            ></i>
                          </a>
                          <a href="#" className="px-2 mx-1">
                            <i className="fa-brands fa-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-3 col-xxl-3">
                <section id="block-11" className="widget widget_block">
                  <div className="footerlogo">
                    <a href="/">
                      <br />
                      <img
                        decoding="async"
                        src={require('../assets/images/rr.jpg')}
                        alt="logo"
                        style={{width:'150px'}}
                        className="img-fluid"
                      />
                      <br />
                    </a>
                    <p>RMV International</p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </footer>
      </section>
      <a
        href="https://wa.me/918980450755?"
        className="d-flex justify-content-end whatsap"
        id="okapi-wasb-button"
        target="_blank"
      >
        <img
          id="okapi-wasb-icon"
          src="https://shivcnc.com/wp-content/plugins/wa-sticky-button/assets/default.png"
        />
      </a>
      <div class="fotterend d-flex align-item-center justify-content-center">
        <p>© Copyright 2023 RMV INTERNTIONAL. All Right Reserved </p>
      </div>
    </>
  );
}

export default Footer;
