import React from 'react'
import Hoc from './Hoc'
import Slider from 'react-slick';

function Stamping() {

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    swipe: true, // touch swipe
    arrows: false, //nex and previous button off
    pauseOnHover: false, // Set to false to continue autoplay on hover
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
   <>
   {/* bacground image */}
   <div className='bg_stamping d-flex justify-content-center align-items-center'>

<div className=' tex' data-aos="zoom-in"
  data-aos-delay="50"
  data-aos-duration="1500">
    <h4 className='text-white text-center container'>
    CNC turret punching machines create parts by successively punching a series of shapes into sheet material.Precision Metal Works offers cost-effective punching solutions whether you need a hundred parts or a large production order.
    </h4>
</div>

</div>
   


{/* conten and img */}

<div className='container'>

    <div className='row'>
 
 <div className='col-lg-6 '>

    <div className='pb-3 p-3'>
        {/* <h2 className='fs-4 bor p-2'>PRECISION MACHINING SERVICES :</h2> */}
    </div>

    <div className='pt-0 mt-5 content_precision'>
    <h2 className='fw-bold borderbotoom'>Stamping & Punching </h2>
<p className='fw-bold'>
RMV International offers metal stamping services to produce parts and components for a broad spectrum of industries, from aerospace, automobile, medical-pharmaceutical, oil-gas, energy, infrastructure.
</p>
<p className='mt-4'>
Metal stamping & Punching applies high pressures to a flat metal sheet via a tool which presses the workpiece against a die to form the final net shape. Stamping processes vary by product and may consist of one or several operations performed either simultaneously or sequentially
</p>
<p className='mt-4'>
The equipment used to perform these operations, known as stamping presses, may be operated by either hydraulic or mechanical means. RMV International offers short run metal stamping services for production runs of up to 20,000 components.</p>

    </div>

  



 </div>


<div className='col-lg-6'>

   <div className='p-5'>
   <img src={require('../assets/images/stamping_content.png')} className='img-fluid' alt="" />
   </div>

</div>

<div className='content_precision mt-5'>
 <h2 className='fw-bold borderbotoom'>Types of Stamping Operations
</h2>

<p >
Depending on the requirements of the final product, stamping may entail one or more of the following operations:
</p>


<h5 className='fw-bold'>Blanking</h5>

<p className='mt-2'>Blanking is the process of cutting the stock metal sheet or coil into smaller pieces in preparation for stamping operations. The material that is removed is called the blank.</p>


<h5 className='fw-bold mt-4'>Piercing</h5>
<p className='mt-2'>Blanking is the process of cutting the stock metal sheet or coil into smaller pieces in preparation for stamping operations. The material that is removed is called the blank.</p>


<h5 className='fw-bold mt-4'>Forming</h5>
<p className='mt-2'>Forming is similar to bending, except multiple bends are created on the workpiece simultaneously to create a part with a specific shape.</p>


<h5 className='fw-bold mt-4'>Air Bending</h5>
<p className='mt-2'>Air bending involves using a punching tool to force the metal sheet into a V-shaped die. This process is named such because the angle of the V-opening is typically deeper than that of the final shape.</p>


<h5 className='fw-bold mt-4'>Bottoming and Coining</h5>
<p className='mt-2'>These processes are similar to air bending. However, significantly more pressure (2 to 30 times more) is used to create a permanent bend in the material.</p>


<h4 className='fw-bold mt-5 borderbotoom'>Benefits of Metal Stamping</h4>
<p>One of the main advantages of metal stamping is its accuracy and ability to meet tight tolerances. Each stroke of the punching tool is precisely programmed to perform forming operations with a high degree of precision. This results in the creation of high-quality parts and components that meet or exceed industry specifications.</p>

<p className='mt-2'>Since metal stamping processes are fully automated, parts can be produced with significantly faster lead times and with a high degree of repeatable accuracy, enabling large-scale production runs to be performed without compromise on quality. The precision of the metal stamping process also ensures that the vast majority of the stock material is used, resulting in the production of less scrap material. This combination of automation, speed, and waste reduction ultimately leads to a significant decrease in overall operating costs.</p>

    </div>



    </div>




</div>



{/* slider */}

<div className='mb-5'>
<Slider {...settings}>



<div className='p-3'>

<img src={require('../assets/serviceimg/Stamping & Punching/download (1).jpeg')} className='img-fluid w-100 object-fit-cover ' style={{height:'200px'}}  alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/Stamping & Punching/download (2).jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'200px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/Stamping & Punching/images.jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'200px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/Stamping & Punching/download.jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'200px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/Stamping & Punching/images (1).jpeg')} className='img-fluid w-100 object-fit-cover'style={{height:'200px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/Stamping & Punching/images (3).jpeg')} style={{height:'200px'}} className='img-fluid w-100 object-fit-cover'  alt="" />

</div>



</Slider>
</div>


<div className='container content_precision'>
    

 <h3 className='borderbotoom'>Full Service Metal Stamping & Punching</h3>

 <p>
 RMV International offers a variety of metal stamping services to fabricate almost any metal part or component for a wide variety of applications. Our range of additional services also enables us to provide single-source solutions for all your fabrication needs.

 </p>

<p>
Are you ready to engage RMV International for your next metal stamping project? Feel free to contact our team to get started.</p>
</div>
   
   </>
  )
}

export default Hoc(Stamping)