import React from 'react'
import Hoc from './Hoc'
import Slider from "react-slick";

function MetalFabrication() {
      
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    swipe: true, // touch swipe
    arrows: false, //nex and previous button off
    pauseOnHover: false, // Set to false to continue autoplay on hover
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
    
    <div className='bg_metal d-flex justify-content-center align-items-center'>

<div className=' tex' data-aos="zoom-in"
  data-aos-delay="50"
  data-aos-duration="1500">
    <h4 className='text-white container text-center'>
    Sheet metal fabrication is a subtractive manufacturing process involving forming parts from thin metal sheets by cutting, bending, folding, punching, and assembling.
    </h4>
</div>

</div>


{/* content image */}

<div className='container mt-5'>

<div className='row'>


    <div className='col-lg-6'>

<div className='content_precision'>
    <h2 className='fw-bold borderbotoom'>Metal Fabrication</h2>

<p>
Fabrication is the act of taking raw stock material and turning it into a part for use in an assembly process. There are many different types of fabrication processes. The most common are
</p>

<p>
<span className='fw-bold d-block'>Cutting:</span> There are many ways to cut nowadays. The old standby is the saw. Others now include plasma torches, water jets, and lasers. There is a wide range of complexity and price, with some machines costing in the millions.
</p>

<p className='mt-3'>
<span className='fw-bold d-block'>Folding:</span> Some parts need to be bent. The most common method is a press brake (or brake press). It has a set of dies that pinches the metal to form a crease. This operation can only be performed in very specific cases due to the movement of the part and the possible shape of the
</p>
<p className='mt-3'>
<span className='fw-bold d-block'>Machining:</span> This is the process of removing metal from a piece of material. It might be done on a lathe, where the material rotates against a cutting tool, or in some other cutting machine where a rotating tool is moved in a variety of ways against a stationary piece. Drills fall into this latter category. The range of motion of the cutting head is defined by the number of axes (i.e. a 3-axis machine).
</p>
<p className='mt-3'>
<span className='fw-bold d-block'>Punching:</span>  Punching is the act of a punch and a die forming a ‘scissor’ effect on a piece of metal to make a hole in it. Obviously, the punch and die must be the same shape and size of the desired hole. In some cases, the main piece of material is kept, as in when holes are added for fasteners. In other cases, the piece that is removed is the desired product-this is called ‘blanking’.
</p>
<p className='mt-3'>
<span className='fw-bold d-block'>Shearing:</span>   Shearing is the process of making a long cut on a piece of metal. It is, in effect, just like the action of one of those paper cutters with the long chop-handle. This is done on sheet metal.
</p>
<p className='mt-3'>
<span className='fw-bold d-block'>Stamping:</span>   Stamping is very similar to punching, except the material is not cut. The die is shaped to make a raised portion of material rather than penetrating.
</p>
<p className='mt-3'>
<span className='fw-bold d-block'>Welding:</span>   Welding is the act of joining two pieces of metal together. A variety of types of welding exist for use in different applications and for the range of metals used in manufacturing.
</p>

</div>



    </div>

<div className='col-lg-6'>
<div className='mt-5'>
    
<img src={require('../assets/images/metal content.jpg')} alt="" className='img-fluid '/>

</div>

</div>


</div>
    


</div>
    
<div className='mb-5'>
<Slider {...settings}>



<div className='p-3'>

<img src={require('../assets/serviceimg/fabrication/download (1).jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/fabrication/images (3).jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/fabrication/images.jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/fabrication/download (2).jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/fabrication/images (2).jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/fabrication/download.jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>
<div className='p-3'>

<img src={require('../assets/serviceimg/fabrication/images (1).jpeg')} className='img-fluid w-100 object-fit-cover' style={{height:'230px'}} alt="" />

</div>



</Slider>
</div>
    </>
  )
}

export default Hoc(MetalFabrication)