import React from 'react'
import Hoc from './Hoc'

function HardwareItem() {

 const hardwaredata=[
    
    {
      img:require('../assets/industry/hardware/hardware//fire sefty/1.jpg'),
      

    },
    {
      img:require('../assets/industry/hardware/hardware/fire sefty/2.jpg'),
      

    },
    {
      img:require('../assets/industry/hardware/hardware/fire sefty/3.jpg'),
      

    },
    {
      img:require('../assets/industry/hardware/hardware/fire sefty/4.jpg'),
      

    },

  ]

  // pipe
 const pipe=[
    
    {
      img:require('../assets/industry/hardware/hardware/pipe conecter/2.jpg'),
      

    },
    {
      img:require('../assets/industry/hardware/hardware/pipe conecter/7.jpg'),
      

    },
    {
      img:require('../assets/industry/hardware/hardware/pipe conecter/8.jpg'),
      

    },
    {
      img:require('../assets/industry/hardware/hardware/pipe conecter/Kupferfittings_4062.jpg'),
      

    },

  ]


  // brackets
 const brackets=[
    
    {
      img:require('../assets/industry/hardware/hardware/steel bracts/2.jpeg'),
      

    },
    {
      img:require('../assets/industry/hardware/hardware/steel bracts/product22.jpg'),
      

    },
    {
      img:require('../assets/industry/hardware/hardware/steel bracts/product25.jpg'),
      

    },
    {
      img:require('../assets/industry/hardware/hardware/steel bracts/product26.jpg'),
      

    },

  ]
  return (
    <>


<div className='container my-4'>

  <h4>We manufacture all following parts from RMV International as per customer requirement.  It is used in <span className='fw-bold'>fire safety, furniture, fabrication, wooden hardware and fire pits etc.</span>  Samples of which are here.</h4>


</div>

    
    <section className="our_product categoryproduct" data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1500">
      <div className="container">
        <div className="our_pro">
          <h2>Fire Sefty Parts</h2>
        </div>
        <div className="parent_hold">
          <div className="row justify-content-center">
            {
              hardwaredata.map((x,i)=>{
                return(
                  <>
                  
            <div className="col-lg-3">
              <div className="image1">
                {/* <a className="tittlename" >{x.title}</a> */}
                <a >
                  <img src={x.img} alt="" className="img-fluid object-fit-contain" />
                </a>
              </div>
            </div>
                  
                  
                  </>
                )
              })

            }     
            
          </div>
        </div>
        
      </div>
    </section>

    {/* pipe connecte */}
    <section className="our_product categoryproduct" data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1500">
      <div className="container">
        <div className="our_pro">
          <h2>Pipe Connector</h2>
        </div>
        <div className="parent_hold">
          <div className="row justify-content-center">
            {
              pipe.map((x,i)=>{
                return(
                  <>
                  
            <div className="col-lg-3">
              <div className="image1">
                {/* <a className="tittlename" >{x.title}</a> */}
                <a className=''>
                  <img src={x.img} alt="" className="img-fluid object-fit-contain" />
                </a>
              </div>
            </div>
                  
                  
                  </>
                )
              })

            }     
            
          </div>
        </div>
        
      </div>
    </section>

    {/* Brackets */}
    <section className="our_product categoryproduct" data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="1500">
      <div className="container">
        <div className="our_pro">
          <h2>Brackets</h2>
        </div>
        <div className="parent_hold">
          <div className="row justify-content-center">
            {
              brackets.map((x,i)=>{
                return(
                  <>
                  
            <div className="col-lg-3">
              <div className="image1">
                {/* <a className="tittlename" >{x.title}</a> */}
                <a className=''>
                  <img src={x.img} alt="" className="img-fluid object-fit-contain" />
                </a>
              </div>
            </div>
                  
                  
                  </>
                )
              })

            }     
            
          </div>
        </div>
        
      </div>
    </section>
    
    </>
  )
}

export default Hoc(HardwareItem)