import React from 'react'
import Hoc from './Hoc'

function Gallery() {

const gallery = [
  {
    img: require('../assets/gallery/009dceb6ad5a98f42c43de10f0db23af.jpg') 
  },
  {
    img: require('../assets/gallery/0661b4a9c54f01742f5a9f6382bd393f.jpg') 
  },
  {
    img: require('../assets/gallery/1000_F_528651769_ZPrSN03bTjZ7zgaqTslEkz1bJe4xk81T.jpg') 
  },
  {
    img: require('../assets/gallery/2.jpg') 
  },
  {
    img: require('../assets/gallery/3 (1).jpg') 
  },
  {
    img: require('../assets/gallery/3.jpg') 
  },
  {
    img: require('../assets/gallery/613f1a97c30600abcb9926f42974fb46.jpg') 
  },
  {
    img: require('../assets/gallery/Ghana,Accra.jpg') 
  },
  {
    img: require('../assets/gallery/IMG-20230922-WA0036.jpg') 
  },
  {
    img: require('../assets/gallery/IMG-20240116-WA0011.jpg') 
  },
  {
    img: require('../assets/gallery/IMG-20240116-WA0015.jpg') 
  },
  {
    img: require('../assets/gallery/IMG-20240116-WA0016.jpg') 
  },
  {
    img: require('../assets/gallery/IMG-20240220-WA0065.jpg') 
  },
  {
    img: require('../assets/gallery/IMG-20240311-WA0002.jpg') 
  },
  {
    img: require('../assets/gallery/Screenshot_20240227-110730.jpg') 
  },
  {
    img: require('../assets/gallery/Screenshot_20240227-124433.jpg') 
  },
  {
    img: require('../assets/gallery/a9b62ef327ed33cf8b13364be8b5be99.jpg') 
  },
  {
    img: require('../assets/gallery/aec30df38f4a0ce220b44c1b0d496a7e.jpg') 
  },
  {
    img: require('../assets/gallery/aec8d3b969eb36d1d5310aeb927e401a.jpg') 
  },
  {
    img: require('../assets/gallery/c71a0ebfa90c14c4436dd9d7e96c5f2f.jpg') 
  },
  {
    img: require('../assets/gallery/canada,totonto.jpg') 
  },
  {
    img: require('../assets/gallery/d6095a19b5ade3b812615488bde90306.jpg') 
  },
  {
    img: require('../assets/gallery/db20d44640f80ba66be17b17d2bff617.jpg') 
  },

  {
    img: require('../assets/gallery/e8b4e48b0d48f3c950c826fbf90af5a0.jpg') 
  },
  {
    img: require('../assets/gallery/india,surat.jpg') 
  },
  {
    img: require('../assets/gallery/packing-delivery-1200x600.jpg') 
  },
  {
    img: require('../assets/gallery/product21.jpg') 
  },
  {
    img: require('../assets/gallery/product27.jpg') 
  },
  {
    img: require('../assets/gallery/screenshot-20220902-231434-photos-500x500.webp') 
  },
  {
    img: require('../assets/gallery/11.jpg') 
  },
  {
    img: require('../assets/gallery/12.jpg') 
  },
  {
    img: require('../assets/gallery/13.jpg') 
  },
  {
    img: require('../assets/gallery/14.jpg') 
  },

]

  return (
    <>
<div className='color'>

<div className='container my-5'>


<div className='row'>

{
  gallery.map((x, i) => (
    <div className='col-lg-3 col-md-6 col-sm-6 imghover mt-5' key={i}>
      <div className=' img-b'>
        <img src={x.img} className='img-fluid' alt="" />
      </div>
    </div>
  ))
}


</div>



</div>


</div>
    
    
    
    </>
  )
}

export default Hoc(Gallery)